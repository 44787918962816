import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spring, animated, config } from '@react-spring/web';

import styles from './EmojiReactionsOverlay.module.css';

const EmojiReactionsOverlay = () => {
  const reaction = useSelector((state) => state.emojiReactions.reaction);
  const [reactions, setReactions] = useState([]);
  const overlayRef = useRef(null);
  const emojiWidth = 40;

  useEffect(() => {
    if (!reaction.emoji) {
      return;
    }
    const width = overlayRef.current.offsetWidth - 2 * emojiWidth;
    setReactions((reactions) => [...reactions, {
      emoji: reaction.emoji,
      username: reaction.username,
      timeStamp: reaction.timeStamp,
      xOffset: Math.floor(Math.random() * width) - (width / 2),
    }]);
    console.debug(reaction);

  setTimeout(() => {
    setReactions((reactions) => {
      if (reactions.length === 1) {
        return [];
      } else {
        return reactions.slice(1);
      }
    });
  }, 1800);
}, [reaction]);

return (
  <div className={ styles.EmojiReactionsOverlay }
    ref={ overlayRef }>
    { reactions.map((reaction, index) => (
      <Spring key={ index }
        from={ { opacity: 0, y: 0 } }
        to={ async (next) => {
          await next({ opacity: 1, y: -100 });
        } }
        config={ config.wobbly }
      >
        { style =>
          <animated.div className={ styles.Emoji }
            style={ { ...style, left: `calc(50% + ${reaction.xOffset}px)` } }>
            { reaction.emoji + ' ' + reaction.username }
          </animated.div> }
      </Spring>
    )) }
  </div >
);
};

export default EmojiReactionsOverlay;
