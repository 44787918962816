import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-R6CQZN154K');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        '/service-worker.js', {
          scope: '/'
        }
      );
      if (registration.installing) {
        console.debug('Service worker installing');
      } else if (registration.waiting) {
        console.debug('Service worker installed');
      } else if (registration.active) {
        console.debug('Service worker active');
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

registerServiceWorker();
