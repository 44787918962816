import styles from './NotFound.module.css';

const NotFound = () => {
    return (
        <div className={styles.NotFound}>
            <h1>On a Quest for the Missing Page ⚔️</h1>
            <p>
                Well, this isn't where we wanted to take you! It looks like the
                page you're looking for has gone on a little adventure.
            </p>
            <p>
                Don't worry, it'll come back. In the meantime, why not check out
                our other pages? They're all pretty great (if we do say so ourselves).
            </p>
            <p>
                If you're really missing this page, you can always send a search party by
                contacting our support team at hello@paperwave.xyz
            </p>
        </div>
    );
};

export default NotFound;
