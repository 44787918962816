import { Button, Tooltip } from '@mui/joy';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../config';
import { addToPlaylist } from '../playlist/playlistSlice';
import { changeVideo, changeViewMode, setVideoDetails } from '../rooms/roomSlice';
import styles from './RelatedVideos.module.css';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { PERMISSIONS, UserPermissionsContext } from '../access_control_list/UserPermissionsProvider';

const RelatedVideos = () => {
  const dispatch = useDispatch();
  const videoDetails = useSelector(state => state.room.videoDetails);
  const videoId = useSelector(state => state.room.videoId);
  const hasPermissionFor = useContext(UserPermissionsContext);
  const lastVideoId = useRef('');
  const viewMode = useSelector((state) => state.room.viewMode);

  useEffect(() => {
    if (!videoId) {
      return;
    }
    lastVideoId.current = videoId;
    fetch(`${config.youtubeSearchApiEndpoint}/video-details?videoId=${videoId}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Failed to fetch video details');
        }
        return response.json();
      })
      .then((data) => {
        dispatch(setVideoDetails(data));
      });
  }, [videoId]);

  return (
    <div className={styles.RelatedVideos}>
      <h2>Recommended Videos</h2>
      {!videoDetails && (<p>
        Load a video to get suggestions.
      </p>)}
      {videoDetails && (
        <div className={styles.Suggestions}>
          {videoDetails.suggestion.map((suggestion) => (
            <div key={suggestion.title} className={styles.Suggestion}>
              <div className={styles.Thumbnail}>
                <img src={suggestion.thumbnail[0].url} alt={suggestion.title} />
              </div>
              <div className={styles.Description}>
                <Tooltip title={suggestion.title} placement="left">
                  <div className={styles.Title}>
                    {suggestion.title}
                  </div>
                </Tooltip>
                <div className={styles.Channel}>
                  {suggestion.channelTitle}
                </div>
              </div>
              {hasPermissionFor(PERMISSIONS.mediaControl) && (
                <div className={styles.Actions}>
                  <Tooltip title="Add to playlist" placement="bottom">
                    <Button
                      sx={{
                        padding: '4px',
                        backgroundColor: 'var(--grey-input)',
                      }}
                      onClick={() => {
                        dispatch(addToPlaylist({
                          id: suggestion.id,
                          title: suggestion.title,
                          thumbnail: suggestion.thumbnail[0].url,
                          channel: suggestion.channelTitle,
                          duration: suggestion.length.simpleText,
                        },
                        ));
                      }}>
                      <PlaylistAddRoundedIcon className={styles.ActionIcon} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Play now" placement="bottom">
                    <Button
                      sx={{
                        padding: '4px',
                        backgroundColor: 'var(--grey-input)',
                      }}
                      onClick={() => {
                        let setOffMs = 0;
                        if (viewMode !== 'YOUTUBE') {
                          dispatch(changeViewMode('YOUTUBE'));
                          setOffMs = 10;
                        }
                        setTimeout(() => {
                        dispatch(changeVideo(suggestion.id));
                        }, setOffMs);
                      }}>
                      <PlayArrowRoundedIcon className={styles.ActionIcon} />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RelatedVideos;
