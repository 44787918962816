import chatInterceptor from '../chat/chatInterceptor';
import mediaControlInterceptor from '../rooms/mediaControlInterceptor';
import emojiReactionsInterceptor from '../emoji_reactions/emojiReactionsInterceptor';
import playlistInterceptor from '../playlist/playlistInterceptor';
import userInterceptor from '../user/userInterceptor';
import webRTCInterceptor from '../webrtc/webRTCInterceptor';
import accessControlListInterceptor from '../access_control_list/accessControlListInterceptor';
import youtubeShortsInterceptor from '../youtube_shorts/youtubeShortsInterceptor';

const socketInterceptors = {
  'room': mediaControlInterceptor,
  'chat': chatInterceptor,
  'emojiReactions': emojiReactionsInterceptor,
  'playlist': playlistInterceptor,
  'user': userInterceptor,
  'webRTC': webRTCInterceptor,
  'accessControlList': accessControlListInterceptor,
  'youtubeShorts': youtubeShortsInterceptor,
};

export default socketInterceptors;
