import { configureStore } from '@reduxjs/toolkit';
import socketMiddleware from '../websocket/socketMiddleware';
import roomReducer from '../rooms/roomSlice';
import chatReducer from '../chat/chatSlice';
import emojiReactionsReducer from '../emoji_reactions/emojiReactionsSlice';
import searchReducer from '../search/SearchSlice';
import playlistReducer from '../playlist/playlistSlice';
import userReducer from '../user/userSlice';
import historyReducer from '../history/historySlice';
import webRTCReducer from '../webrtc/webRTCSlice';
import accessControlListReducer from '../access_control_list/accessControlListSlice';
import youtubeShortsReducer from '../youtube_shorts/youtubeShortsSlice';


export const store = configureStore({
  reducer: {
    room: roomReducer,
    chat: chatReducer,
    emojiReactions: emojiReactionsReducer,
    search: searchReducer,
    playlist: playlistReducer,
    user: userReducer,
    history: historyReducer,
    webRTC: webRTCReducer,
    accessControlList: accessControlListReducer,
    youtubeShorts: youtubeShortsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(socketMiddleware)
});
