import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    entries: [],
};

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setHistory: (state, action) => {
            state.entries = action.payload;
        },
    },
});

export const { setHistory } = historySlice.actions;

export default historySlice.reducer;
