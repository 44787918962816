import { setCallData } from './webRTCSlice';

const webRTCMessageHandler = (store, action, message) => {
    switch (message.action) {
        case 'SEND_SIGNAL':
            store.dispatch(setCallData(message.payload));
            break;
        default:
            console.warn('Unknown message action', message.action);
    }
};

export default webRTCMessageHandler;
