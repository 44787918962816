import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    peers: [],
    callData: null
};

export const webRTCSlice = createSlice({
    name: 'webRTC',
    initialState,
    reducers: {
        sendSignal: () => {
            return;
        },
        addPeer: (state, action) => {
            state.peers.push(action.payload);
        },
        setCallData: (state, action) => {
            state.callData = action.payload;
        },
    },
});

export const { sendSignal, addPeer, setCallData } = webRTCSlice.actions;

export default webRTCSlice.reducer;
