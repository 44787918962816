import { setHistory } from './historySlice';

const historyMessageHandler = (store, action, message) => {
    switch (message.action) {
        case 'UPDATE_HISTORY':
            const sortedHistory = message.payload.sort((a, b) => b.timestamp - a.timestamp);
            store.dispatch(setHistory(sortedHistory));
            break;
        default:
            console.warn('Unknown message action', message.action);
    }
};

export default historyMessageHandler;
