import { Button, FormLabel, Modal, ModalDialog, Slider, TextField } from '@mui/joy';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser, setIsEditUserModalOpen } from './userSlice';

import styles from './EditUserModal.module.css';
import { useState } from 'react';
import config from '../config';

const EditUserModal = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const isEditUserModalOpen = useSelector((state) => state.user.isEditUserModalOpen);
    const [color, setColor] = useState(currentUser.color.match(/\d+\s/)[0].replace(' ', ''));
    const [username, setUsername] = useState(currentUser.name);
    const roomId = useSelector((state) => state.room.roomId);
    const [error, setError] = useState(null);

    const handleEditUserFormSubmit = (event) => {
        event.preventDefault();
        fetch(`${config.serverUrl}/user/update?roomId=${roomId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                privateId: currentUser.privateId,
                publicUsername: username,
                color: `hsl(${color} 60%  61%)`,
            }),
        })
            .then(async (response) => {
                if (!response.ok) {
                    setError((await response.json()).message);
                    throw new Error('Could not update user info');
                }
                dispatch(setIsEditUserModalOpen(false));
                const user = {
                    ...JSON.parse(localStorage.getItem('user')),
                    name: username,
                    color: `hsl(${color} 60%  61%)`,
                };
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(setCurrentUser(user));
            });
    };

    const updateUsername = (event) => {
        setUsername(event.target.value);
        if (error) {
            setError(null);
        }
    };

    const updateColor = (event) => {
        setColor(event.target.value);
    };

    return (
        <>
            <Modal open={isEditUserModalOpen} onClose={() => dispatch(setIsEditUserModalOpen(false))}>
                <ModalDialog
                    aria-labelledby="Create public room"
                    aria-describedby="Provide room name and tags for discovery"
                    sx={{
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'none',
                        border: 'none'
                    }}>
                    <h2>Update User Info</h2>
                    <p>Change your username or display color</p>
                    <form onSubmit={handleEditUserFormSubmit} >
                        <Stack spacing={3}>
                            <TextField onChange={updateUsername}
                                name="username"
                                label="Username"
                                placeholder='Username'
                                defaultValue={currentUser.name}
                                error={error}
                                helperText={error}
                                autoFocus />
                            <FormLabel>Color</FormLabel>
                            <div className={styles.Color}
                                style={{ backgroundColor: `hsl(${color} 60%  61%)` }}
                            ></div>
                            <Slider defaultValue={parseInt(currentUser.color.match(/\d+\s/)[0].replace(' ', ''))}
                                min={0}
                                max={360}
                                step={1}
                                aria-label="Color"
                                onChange={updateColor}
                                sx={{
                                    marginTop: '4px !important',
                                }} />
                            <Button type='submit'>Update</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </>
    );
};

export default EditUserModal;
