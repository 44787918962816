const userInterceptor = (store, action, client) => {
  const baseDestination =
    `/app/room/${store.getState().room.roomId}/user`;
  const type = 'user';

  switch (action.type) {
    case 'user/action':
      // ...
      break;
    default:
      break;
  }
};

export default userInterceptor;
