import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import { QRCode } from 'react-qrcode-logo';
import styles from './ShareRoomQRCodeModal.module.css';

const ShareRoomQRCodeModal = ({ isOpen, close }) => {
    return (
        <Modal className={styles.QRCodeModal}
            open={isOpen}
            onClose={close}>
            <ModalDialog>
                <ModalClose onClick={close} />
                <h2>Share this room</h2>
                <p>Scan this code to open this room on another device, e.g. to control your tv. </p>
                <QRCode value={window.location.href} />
            </ModalDialog>
        </Modal>
    );
};

export default ShareRoomQRCodeModal;
