import { receiveEmojiReaction } from './emojiReactionsSlice';

const emojiReactionsMessageHandler = (store, action, message) => {
    switch (message.action) {
        case 'SEND_EMOJI_REACTION':
            store.dispatch(receiveEmojiReaction(message.emojiReaction));
            break;
        default:
            console.warn('Unknown action', message.action);
    }
};

export default emojiReactionsMessageHandler;
