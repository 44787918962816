import { receiveMessage } from './chatSlice';

const socketChatMessageHandler = (store, action, message) => {
    switch (message.action) {
        case 'SEND_MESSAGE':
            store.dispatch(receiveMessage(message.message));
            break;
        default:
            console.warn('Unknown message action', message.action);
    }
};

export default socketChatMessageHandler;
