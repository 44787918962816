import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reaction: {
    emoji: null,
    username: null,
    timeStamp: null,
  }
};

export const emojiReactionsSlice = createSlice({
  name: 'emojiReactions',
  initialState,
  reducers: {
    sendEmojiReaction: () => {
      return;
    },
    receiveEmojiReaction: (state, action) => {
      state.reaction = action.payload;
    },
  },
});

export const { sendEmojiReaction, receiveEmojiReaction } = emojiReactionsSlice.actions;

export default emojiReactionsSlice.reducer;
