import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  currentUser: null,
  isEditUserModalOpen: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      if (!action.payload) {
        return;
      }
      state.users = action.payload;
    },
    setCurrentUser: (state, action) => {
      if (!action.payload) {
        return;
      }
      state.currentUser = action.payload;
    },
    setIsEditUserModalOpen: (state, action) => {
      if (action.payload === undefined) {
        return;
      }
      state.isEditUserModalOpen = action.payload;
    },
  },
});

export const { setUsers, setCurrentUser, setIsEditUserModalOpen } = userSlice.actions;

export default userSlice.reducer;
