import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connectToWebSocket } from './roomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToRoom } from './roomSlice';
import { setSearchBarOpen, setSearchResultsOpen } from '../search/SearchSlice';

import VideoPlayer from '../video_player/VideoPlayer';

import styles from './Room.module.css';
import RoomTabsContainer from '../layout/RoomTabsContainer';
import UserList from '../user/UserList';
import { useMediaQuery } from 'react-responsive';
import RoomLoadingScreen from './RoomLoadingScreen';
import EditUserModal from '../user/EditUserModal';

const viewModeClasses = {
  'YOUTUBE': styles.YouTubeViewMode,
  'YOUTUBE_SHORTS': styles.YouTubeShortsViewMode,
};

const Room = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isWebSocketConnected = useSelector(state => state.room.isWebSocketConnected);
  const isLoading = useSelector(state => state.room.isLoading);
  const isTabletPortrait = useMediaQuery({
    query: '(orientation: portrait) and (min-width: 768px)',
  });
  const isTabletLandscape = useMediaQuery({
    query: '(orientation: landscape) and (min-width: 800px) and (min-height: 650px)'
  });
  const viewMode = useSelector((state) => state.room.viewMode);

  useEffect(() => {
    document.title = `Room ${location.pathname.split('/').reverse()[0]} - flock.video`;
  }, [location]);

  useEffect(() => {
    dispatch(connectToWebSocket());
    dispatch(setSearchBarOpen(false));
    dispatch(setSearchResultsOpen(false));
    window.onbeforeunload = (e) => {
      e.preventDefault();
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => {
    if (isWebSocketConnected) {
      dispatch(subscribeToRoom({
        roomId: location.pathname.split('/').reverse()[0],
        public: location.state?.public || false,
        roomName: location.state?.roomName || null,
        tags: location.state?.tags || null,
      }));
    }
  }, [isWebSocketConnected]);


  return (
    <>
      {isLoading
        ? <RoomLoadingScreen />
        : <div className={styles.Room}>
          <div className={[styles.PlayerColumn, viewModeClasses[viewMode]].join(' ')}>
            <VideoPlayer />
            {isTabletLandscape && <UserList />}
          </div>
          <div className={styles.RoomTabsContainerWrapper}>
            {isTabletPortrait && <UserList />}
            <RoomTabsContainer />
          </div>
          <EditUserModal />
        </div>
      }
    </>
  );
};

export default Room;
