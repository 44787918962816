import styles from '../page/CommonPage.module.css';


const PrivacyPolicy = () => {
    return (
        <div className={styles.Page}>
            <h1>Privacy Policy</h1>
            <p>
                At flock.video, we are committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and disclose information from our users. By using our services, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.
            </p>
            <h2>Information We Collect</h2>
            <p>
                We collect information from you when you use our services. This information may include your IP address, browser type, device type, operating system, and usage data. We may also collect information that you provide to us directly, such as your email address or other contact information.
            </p>
            <h2>Use of Information</h2>
            <p>
                We use the information we collect to operate and improve our services, to personalize your experience, and to communicate with you. We may also use the information we collect to detect and prevent fraud and other illegal activities.
            </p>
            <h2>Disclosure of Information</h2>
            <p>
                We may disclose your information to third parties in certain circumstances. For example, we may disclose your information to third parties to comply with legal obligations, to enforce our policies, or to protect the rights and safety of our users.
            </p>
            <h2>Cookies</h2>
            <p>
                We use cookies to improve your experience on our platform. We use Google Analytics to track the usage of our platform and to improve it. By using our services, you consent to our use of cookies in accordance with this Privacy Policy.
            </p>
            <h2>Security</h2>
            <p>
                We take the security of your information seriously. We use industry-standard security measures to protect your information from unauthorized access, use, or disclosure. However, no security measures can be 100% effective, and we cannot guarantee the security of your information.
            </p>
            <h2>Changes to Our Privacy Policy</h2>
            <p>
                We may change this Privacy Policy from time to time. If we make changes, we will provide notice of the changes by updating the date of this Privacy Policy and, if the changes are material, we will provide additional notice through our services.
            </p>
            <h2>Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy, please contact us at [email protected]
            </p>
            <p>
                By using our services, you acknowledge that you have read and understand this Privacy Policy, and that you agree to be bound by all of its terms and conditions.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
