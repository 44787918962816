import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import YouTube from 'react-youtube';
import { UserPermissionsContext, PERMISSIONS } from '../access_control_list/UserPermissionsProvider';
import { pause, play } from '../rooms/roomSlice';
import { changeCurrentIndex } from '../youtube_shorts/youtubeShortsSlice';
import videoPlayerStyles from './VideoPlayer.module.css';

import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';

import styles from './YouTubeShortsPlayer.module.css';

const YouTubeShortsPlayer = ({ onReady, onStateChange }) => {
    const dispatch = useDispatch();
    const currentIndex = useSelector(state => state.youtubeShorts.currentIndex);
    const ids = useSelector(state => state.youtubeShorts.ids);
    const youTubePlayerRef = useRef(null);
    const isPlaying = useSelector((state) => state.room.isPlaying);
    const hasPermissionFor = useContext(UserPermissionsContext);
    const currentTime = useSelector(state => state.room.currentTime);
    const initialTime = useSelector((state) => state.room.initialTime);
    const [isMuted, setIsMuted] = useState(false);

    const playNext = () => {
        const nextIndex = currentIndex === ids.length - 1 ? 0 : currentIndex + 1;
        dispatch(changeCurrentIndex(nextIndex));
    };

    const playPrevious = () => {
        const previousIndex = currentIndex === 0 ? ids.length - 1 : currentIndex - 1;
        dispatch(changeCurrentIndex(previousIndex));
    };

    useEffect(() => {
        const player = youTubePlayerRef.current?.internalPlayer;
        player.loadVideoById(ids[currentIndex]);
        setTimeout(() => player.playVideo(), 500);
    }, [currentIndex]);

    useEffect(() => {
        if (!youTubePlayerRef.current) {
            return;
        } else if (isPlaying) {
            youTubePlayerRef.current.internalPlayer.playVideo();
        } else {
            youTubePlayerRef.current.internalPlayer.pauseVideo();
        }
    }, [isPlaying]);

    useEffect(() => {
        const player = youTubePlayerRef.current;
        if (!player) {
            return;
        } else if (isPlaying && !initialTime.time) {
            return;
        }
        player.getInternalPlayer()
            .getCurrentTime()
            .then(time => {
                if (time === Number.parseFloat(currentTime)) {
                    return;
                }
                player.getInternalPlayer().seekTo(currentTime);
            });
    }, [currentTime]);

    const handlePlay = ({ target }) => {
        console.debug('handlePlay', target.playerInfo);
        if (!isPlaying && !hasPermissionFor(PERMISSIONS.mediaControl)) {
            target.pauseVideo();
            target.seekTo(currentTime);
            return;
        } else if (!isPlaying) {
            dispatch(play(target.playerInfo.currentTime));
        }
    };

    const handlePause = ({ target }) => {
        const currentTime = target.playerInfo.currentTime;
        console.debug('handlePause', currentTime);
        if (isPlaying && !hasPermissionFor(PERMISSIONS.mediaControl)) {
            target.playVideo();
            return;
        } else if (isPlaying) {
            dispatch(pause(currentTime));
        }
    };

    const toggleMute = async () => {
        const player = youTubePlayerRef.current.internalPlayer;
        await player.unMute();
        console.log(await player.getVolume());
        if (await (player.getVolume()) === 0) {
            player.setVolume(100);
            setIsMuted(false);
        } else {
            player.setVolume(0);
            setIsMuted(true);
        }
    };


    return (
        <>
            <YouTube
                ref={youTubePlayerRef}
                iframeClassName={videoPlayerStyles.YouTubePlayer}
                videoId={'6rdlxahI2Pg'}
                onEnd={playNext}
                onPlay={handlePlay}
                onPause={handlePause}
                opts={{
                    width: '100%',
                    playerVars: {
                        autoplay: 1,
                        controls: 0,
                        mute: 0,
                        rel: 0,
                        iv_load_policy: 3,
                        disablekb: 1,
                        sandbox: 'allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation'
                    },
                }}
            />
            <div className={styles.YouTubeShortsPlayerOverlay}>
                <div onClick={toggleMute} className={styles.MuteButton}>
                    {isMuted ? <VolumeOffRoundedIcon /> : <VolumeUpRoundedIcon />}
                </div>
                <div>
                    <div className={styles.NavButton} onClick={playPrevious}>
                        <ArrowUpwardRoundedIcon />
                    </div>
                    <div className={styles.NavButton} onClick={playNext}>
                        <ArrowDownwardRoundedIcon />
                    </div>
                </div>
            </div>
        </>
    );
};

export default YouTubeShortsPlayer;
