import Search from '../search/Search';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Room from '../rooms/Room';
import SearchResultsView from '../search/SearchResultsView';
import Home from '../home/Home';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { disconnectFromWebSocket } from '../rooms/roomSlice';
import FAQ from '../faq/FAQ';
import About from '../about/About';
import TermsOfService from '../terms/TermsOfService';
import PrivacyPolicy from '../terms/PrivacyPolicy';
import Footer from '../home/Footer';
import NotFound from '../error/NotFound';

const RoutesContainer = () => {
  const isMobilePortrait = useMediaQuery({
    query: '(orientation: portrait) and (max-width: 760px)'
  });
  const isTabletPortrait = useMediaQuery({
    query: '(orientation: portrait) and (min-width: 760px)',
  });
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname.startsWith('/room')) {
      return;
    }
    dispatch(disconnectFromWebSocket());
  }, [location]);

  return (
    <div className='content'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/room/:roomId"
          handle={true}
          element={<>
            {isMobilePortrait &&
              <Search />
            }
            {(isMobilePortrait || isTabletPortrait) &&
              <SearchResultsView />}
            <Room />
          </>} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about" element={<About />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!location.pathname.startsWith('/room') && <Footer />}
    </div>
  );
};

export default RoutesContainer;
