import socketChatMessageHandler from '../chat/socketChatMessageHandler';
import socketMediaControlMessageHandler from '../rooms/socketMediaControlMessageHandler';
import socketEmojiReactionsMessageHandler from '../emoji_reactions/emojiReactionsMessageHandler';
import playlistMessageHandler from '../playlist/playlistMessageHandler';
import userMessageHandler from '../user/userMessageHandler';
import historyMessageHandler from '../history/historyMessageHandler';
import webRTCMessageHandler from '../webrtc/webRTCMessageHandler';
import accessControlListMessageHandler from '../access_control_list/accessControlListMessageHandler';
import youtubeShortsMessageHandler from '../youtube_shorts/youtubeShortsMessageHandler';

const socketMessageHandlers = {
  'mediaControl': socketMediaControlMessageHandler,
  'chat': socketChatMessageHandler,
  'emojiReactions': socketEmojiReactionsMessageHandler,
  'playlist': playlistMessageHandler,
  'user': userMessageHandler,
  'history': historyMessageHandler,
  'webRTC': webRTCMessageHandler,
  'accessControlList': accessControlListMessageHandler,
  'youtubeShorts': youtubeShortsMessageHandler,
};

export default socketMessageHandlers;
