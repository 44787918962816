import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as TwitterLogo } from '../images/TwitterLogo.svg';
import { ReactComponent as InstagramLogo } from '../images/InstagramLogo.svg';
import { ReactComponent as YouTubeLogo } from '../images/YouTubeLogo.svg';
import { ReactComponent as DiscordLogo } from '../images/DiscordLogo.svg';

import styles from './Footer.module.css';
import { Tooltip } from '@mui/joy';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className={styles.Footer}>
            <h1>Follow the Flock</h1>
            <div className={styles.SocialMedia}>
                <Tooltip title="flock on twitter" placement="bottom">
                    <a href="https://twitter.com/video_flock" target="_blank" rel="noreferrer">
                        <TwitterLogo />
                    </a>
                </Tooltip>
                <Tooltip title="flock on instagram" placement="bottom">
                    <a href="https://www.instagram.com/flock.video" target="_blank" rel="noreferrer">
                        <InstagramLogo />
                    </a>
                </Tooltip>
                <Tooltip title="flock on YouTube" placement="bottom">
                    <a href="#" target="_blank" rel="noreferrer">
                        <YouTubeLogo />
                    </a>
                </Tooltip>
                <Tooltip title="flock on Discord" placement="bottom">
                    <a href="https://discord.gg/A9js2byBQS" target="_blank" rel="noreferrer">
                        <DiscordLogo />
                    </a>
                </Tooltip>
            </div>
            <div className={styles.Links}>
                <a href="https://blog.flock.video" target="_blank" rel="noreferrer">Blog</a>
                <br></br>
                <Link to={'/faq'}>FAQ</Link>
                <Link to={'/about'}>About</Link>
                <Link to={'/termsofservice'}>Terms of Service</Link>
                <Link to={'/privacy'}>Privacy Policy</Link>
            </div>
            <div className={styles.CopyRight}>
                <Logo />
                <div>© 2023 flock.video</div>
            </div>

        </footer>
    );
};

export default Footer;
