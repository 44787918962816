import { Modal, ModalDialog } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import AccessControlListManager from '../access_control_list/AccessControlListManager';
import { setIsRoomSettingsOpen } from './roomSlice';

const RoomSettings = () => {
    const dispatch = useDispatch();
    const open = useSelector(state => state.room.isRoomSettingsOpen);

    return (
        <Modal open={open} onClose={() => dispatch(setIsRoomSettingsOpen(false))}>
            <ModalDialog>
                <AccessControlListManager />
            </ModalDialog>
        </Modal>
    );
};

export default RoomSettings;
