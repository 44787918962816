import { ReactComponent as Logo } from '../images/logo.svg';
import { useSpring, animated, Spring } from '@react-spring/web';
import styles from './RoomLoadingScreen.module.css';
import { useEffect } from 'react';

const RoomLoadingScreen = () => {
    const [props] = useSpring(() => ({
        from: { rotate: 0 },
        to: { rotate: 360 },
        loop: { reverse: true },
        config: { mass: 100, tension: 50, },
    }));

    return (
        <div className={styles.RoomLoadingScreen}>
            <animated.div style={props}>
                <Logo />
            </animated.div>
            <h1>Connecting to room...</h1>
        </div >
    );
};

export default RoomLoadingScreen;
