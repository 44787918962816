import styles from '../page/CommonPage.module.css';

const About = () => {
    return (
        <div className={styles.Page}>
            <h1>About</h1>
            <h2>Welcome to flock.video,</h2>
            <p>
                the revolutionary platform that
                allows you to watch YouTube videos in sync with friends
                or strangers. Whether you're looking to study together,
                do sports together, or simply watch someone react to
                different videos, flock.video makes it easy to connect
                with others who share your interests.
            </p>

            <p>
                Our platform was created with the goal of enhancing the
                online video watching experience. We understand that
                watching videos can be a solitary experience, and we
                wanted to change that. With flock.video, you can join
                private and public rooms to watch videos with friends,
                classmates, or even strangers from all over the world.
            </p>
            <p>
                We've made it easy to create and join rooms, synchronize
                videos, and interact with other users. With just a few clicks,
                you can start watching videos together in perfect sync. Our
                platform is designed to be user-friendly and intuitive,
                so you can start enjoying the benefits of flock.video right away.
            </p>
            <p>
                In addition to watching videos, flock.video also offers a great
                way to interact with others who share your interests. You can
                discuss videos, share your thoughts, and even make new friends.
                Whether you're looking to study together, do sports together, or
                simply watch someone react to different videos, flock.video has
                something for everyone.
            </p>
            <p>
                We are constantly working to improve our platform and add new features.
                If you have any suggestions or feedback, please don't hesitate to
                contact us. We're always happy to hear from our users.
            </p>
            <p>
                Thank you for choosing flock.video. We hope you enjoy using our platform
                as much as we enjoyed creating it.
            </p>
        </div>
    );
};

export default About;
