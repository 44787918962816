const chatInterceptor = (store, action, client) => {
    const baseDestination = `/app/room/${store.getState().room.roomId}/chat`;
    const type = 'chat';

    switch (action.type) {
        case 'chat/sendMessage':
            client.publish({
                destination: `${baseDestination}/send-message`,
                body: JSON.stringify({
                    type,
                    action: 'SEND_MESSAGE',
                    payload: action.payload,
                }),
            });
            break;
        default:
            break;
    }
};

export default chatInterceptor;
