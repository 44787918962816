import styles from '../page/CommonPage.module.css';

const TermsOfService = () => {
    return (
        <div className={styles.Page}>
            <h1>Terms of Service</h1>
            <p>
                Welcome to flock.video, a platform that allows you to watch YouTube videos in sync with friends or strangers. By accessing or using our services, you agree to be bound by the terms and conditions set forth in this agreement (the "Terms of Service" or "TOS"). If you do not agree to these terms, you may not use our services.
            </p>
            <h2>Use of Services</h2>
            <p>
                You are responsible for your use of the services and for any content you provide, including compliance with applicable laws, rules, and regulations. You should only provide content that you are comfortable sharing with others.
            </p>
            <h2>Privacy Policy</h2>
            <p>
                We take the privacy of our users seriously. Please refer to our Privacy Policy for information on how we collect, use and disclose information from our users.
            </p>
            <h2>Content</h2>
            <p>
                You understand that all information, data, text, software, music, sound, photographs, graphics, video, messages or other materials ("Content"), whether publicly posted or privately transmitted, are the sole responsibility of the person from whom such Content originated. This means that you, and not flock.video, are entirely responsible for all Content that you upload, post, email or otherwise transmit via the services. Any illegal, racist, pornographic, or any other type of offensive or inappropriate content is strictly forbidden on our platform. Any violation of this rule will result in the immediate termination of your account and possible legal action.
            </p>
            <h2>Cookies</h2>
            <p>
                We use cookies to improve your experience on our platform. We use Google Analytics to track the usage of our platform and to improve it. By using our services, you consent to our use of cookies in accordance with our Privacy Policy.
            </p>
            <h2>Intellectual Property</h2>
            <p>
                The services and all materials incorporated by flock.video on the services (including, but not limited to, text, graphics, images, logos, audio, and video) are protected by copyrights, trademarks, service marks, or other proprietary rights and laws.
            </p>
            <h2>Termination</h2>
            <p>
                flock.video reserves the right to terminate your access to the services at any time, without notice, for any reason, including but not limited to, a breach of the TOS.
            </p>
            <h2>Disclaimer of Warranties</h2>
            <p>
                flock.video provides the services "as is" and "as available" without warranty of any kind, either express or implied, including but not limited to, the implied warranties of merchantability and fitness for a particular purpose. flock.video does not warrant that the services will be uninterrupted or error-free, nor does it make any warranty as to the results that may be obtained from use of the services.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
                In no event shall flock.video be liable for any damages whatsoever, including but not limited to any direct, indirect, special, incidental, consequential or punitive damages, arising out of or in connection with the use or inability to use the services or any content thereon.
            </p>
            <h2>Governing Law</h2>
            <p>
                These TOS shall be governed by and construed in accordance with the laws of the country in which flock.video is headquartered, without giving effect to any principles of conflicts of law.
            </p>
            <p>
                By using our services, you acknowledge that you have read and understand the TOS and Privacy Policy, and that you agree to be bound by all of their terms and conditions
            </p>
        </div>);
};

export default TermsOfService;
