import { setPlaylist } from './playlistSlice';

const playlistMessageHandler = (store, action, message) => {
    switch (message.action) {
        case 'UPDATE':
            store.dispatch(setPlaylist(message.payload));
            break;
        default:
            console.warn('Unknown message action', message.action);
    }
};

export default playlistMessageHandler;
