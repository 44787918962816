import { useDispatch, useSelector } from 'react-redux';
import styles from './UserList.module.css';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { setIsEditUserModalOpen } from './userSlice';
import { Tooltip } from '@mui/joy';
import WebRTC from '../webrtc/WebRTC';

const UserList = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);
  const currentUser = useSelector((state) => state.user.currentUser);

  return (
    <div className={styles.UserList}>
      {/** <WebRTC /> */}
      {users.filter(u => u.online).map((user) => (
        <div className={styles.User} key={user.publicId}>
          <div className={styles.UserName} style={{ color: user.color }} >
            <div className={styles.Left}>
              {user.publicId === currentUser.publicId &&
                <GroupRoundedIcon className={styles.CurrentUserIcon} />
              }
              {user.name}
            </div>
          </div>
          <div className={styles.Right}>
            {user.publicId === currentUser.publicId &&
              <Tooltip title={'Edit User'} placement="bottom">
                <EditRoundedIcon className={styles.ActionIcon}
                  onClick={() => dispatch(setIsEditUserModalOpen(true))} />
              </Tooltip>
            }
          </div>
        </div>
      ))
      }
    </div >
  );
};

export default UserList;
