import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import YouTube from 'react-youtube';
import { changePlaybackRate, changeVideo, pause, play } from '../rooms/roomSlice';
import { ReactComponent as NoVideoBackground } from '../images/noVideoBackground.svg';
import styles from './VideoPlayer.module.css';
import { removeFromPlaylist } from '../playlist/playlistSlice';
import EmojiReactionsOverlay from '../emoji_reactions/EmojiReactionsOverlay';
import { useState } from 'react';
import { PERMISSIONS, UserPermissionsContext } from '../access_control_list/UserPermissionsProvider';
import SuggestedVideos from '../youtube/SuggestedVideos';
import { useMediaQuery } from 'react-responsive';
import YouTubeShortsPlayer from './YouTubeShortsPlayer';

const viewModeClasses = {
  'YOUTUBE': styles.YouTubeViewMode,
  'YOUTUBE_SHORTS': styles.YouTubeShortsViewMode,
};

// TODO clean up this spaghetti code... Maybe statemachine?
const VideoPlayer = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector((state) => state.room.viewMode);
  const videoId = useSelector(state => state.room.videoId);
  const currentTime = useSelector(state => state.room.currentTime);
  const isPlaying = useSelector((state) => state.room.isPlaying);
  const youTubePlayerRef = useRef(null);
  const playlist = useSelector((state) => state.playlist.playlist);
  const playbackRate = useSelector((state) => state.room.playbackRate);
  const initialTime = useSelector((state) => state.room.initialTime);
  const hasPermissionFor = useContext(UserPermissionsContext);
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1024px)'
  });

  const [initialaized, setInitialized] = useState(false);
  const videoIdRef = useRef(videoId);

  useEffect(() => {
    if (!initialaized) {
      videoIdRef.current = videoId;
    } else {
      console.log('PLAYER_REF', youTubePlayerRef.current);
      youTubePlayerRef.current?.internalPlayer?.loadVideoById(videoId);
      dispatch(play(0));
    }
  }, [videoId]);

  useEffect(() => {
    if (!youTubePlayerRef.current) {
      return;
    } else if (isPlaying) {
      youTubePlayerRef.current.internalPlayer.playVideo();
    } else {
      youTubePlayerRef.current.internalPlayer.pauseVideo();
    }
  }, [isPlaying]);

  const handleStateChange = ({ target, data }) => {
    console.debug('handleStateChange', data);
    // for keyboard navigation
    // Idea: when buffering while playing it means the user seeked
    // or bad internet connection??? ...
    if (!hasPermissionFor(PERMISSIONS.mediaControl)) {
      return;
    } else if (data === 3 && isPlaying) {
      dispatch(play(target.getCurrentTime()));
    }
  };

  const handlePlay = ({ target }) => {
    console.debug('handlePlay', target);
    if (!isPlaying && !hasPermissionFor(PERMISSIONS.mediaControl)) {
      target.pauseVideo();
      target.seekTo(currentTime);
      return;
    } else if (!isPlaying) {
      dispatch(play(target.getCurrentTime()));
    }
  };

  const handlePause = ({ target }) => {
    const currentTime = target.getCurrentTime();
    console.debug('handlePause', currentTime);
    if (isPlaying && !hasPermissionFor(PERMISSIONS.mediaControl)) {
      target.playVideo();
      return;
    } else if (isPlaying) {
      dispatch(pause(currentTime));
    }
  };

  useEffect(() => {
    const player = youTubePlayerRef.current;
    if (!player) {
      return;
    } else if (isPlaying && !initialTime.time) {
      return;
    }
    player.getInternalPlayer()
      .getCurrentTime()
      .then(time => {
        if (time === Number.parseFloat(currentTime)) {
          return;
        }
        player.getInternalPlayer().seekTo(currentTime);
      });
  }, [currentTime]);

  const handleReady = ({ target }) => {
    console.debug('handleReady', target);
    if (youTubePlayerRef.current?.internalPlayer) {
      window.youtubePlayer = youTubePlayerRef.current?.internalPlayer;
    }
    let t = currentTime;
    if (!isPlaying) {
      target.pauseVideo();
    } else if (initialTime.time) {
      const offset = (Date.now() - initialTime.timeReceived) / 1000;
      // 1 is for latency (it will be calculated later)
      t = initialTime.time + offset + 1;
    }
    target.seekTo(t);
    setInitialized(true);
  };

  const handleEnd = () => {
    console.debug('onEnd');
    if (playlist.videos.length === 0) {
      return;
    }
    const nextId = playlist.videos[0].id;
    const nextShortId = playlist.videos[0].shortId;
    dispatch(removeFromPlaylist(nextShortId));
    dispatch(changeVideo(nextId));
    dispatch(play(0));
  };

  const handlePlaybackRateChange = ({ target, data }) => {
    console.debug('handlePlaybackRateChange', data);
    if (data === playbackRate) {
      return;
    } else if (!hasPermissionFor(PERMISSIONS.mediaControl)) {
      target.setPlaybackRate(playbackRate);
      return;
    }
    dispatch(changePlaybackRate(data));
  };

  useEffect(() => {
    const player = youTubePlayerRef?.current?.internalPlayer;
    if (!player || playbackRate === player.getPlaybackRate()) {
      return;
    }
    player.setPlaybackRate(playbackRate);
  }, [playbackRate]);


  return (
    <div className={[styles.VideoPlayer, viewModeClasses[viewMode]].join(' ')}>
      {
        videoId && viewMode === 'YOUTUBE' &&
        <YouTube
          ref={youTubePlayerRef}
          videoId={videoIdRef.current}
          onPlay={handlePlay}
          onPause={handlePause}
          iframeClassName={styles.YouTubePlayer}
          opts={{
            width: '100%',
            playerVars: {
              autoplay: 1,
              controls: 1,
              mute: 0,
              rel: 0,
              iv_load_policy: 3,
              disablekb: 1,
              sandbox: 'allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation'
            },
          }}
          onStateChange={handleStateChange}
          onReady={handleReady}
          onEnd={handleEnd}
          onPlaybackRateChange={handlePlaybackRateChange}
        />
      }

      {viewMode === 'YOUTUBE_SHORTS' &&
        <YouTubeShortsPlayer />
      }

      {(!videoId && viewMode === 'YOUTUBE') &&
        <div className={styles.NoVideo}>
          <NoVideoBackground className={styles.NoVideoBackground} />
          <h2>
            No Video loaded. <br></br>
            Search or paste a YouTube url 🚀<br></br>
          </h2>
          <h1>
            Invite friends by sharing the link in the address bar.
          </h1>
          {isLargeScreen && <SuggestedVideos />}
        </div>
      }
      <EmojiReactionsOverlay />

    </div>
  );
};

export default VideoPlayer;
