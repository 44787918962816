import TextField from '@mui/joy/TextField';
import SendRounded from '@mui/icons-material/SendRounded';
import { useDispatch, useSelector } from 'react-redux';
import SentimentSatisfiedRoundedIcon from '@mui/icons-material/SentimentSatisfiedRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import styles from './Chat.module.css';
import { sendMessage } from './chatSlice';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Tooltip } from '@mui/joy';
import EmojiPicker from 'emoji-picker-react';
import { useSpring, animated, config } from '@react-spring/web';
import { useMediaQuery } from 'react-responsive';
import EmojiReactions from '../emoji_reactions/EmojiReactions';
import { setIsEditUserModalOpen } from '../user/userSlice';
import { PERMISSIONS, UserPermissionsContext } from '../access_control_list/UserPermissionsProvider';

const Chat = () => {
  const dispatch = useDispatch();
  const messages = useSelector(state => state.chat.messages);
  const messagesRef = useRef(null);
  const messageTextFieldRef = useRef(null);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const isLargeScreen = useMediaQuery({ query: '(min-width: 900px)' });
  const users = useSelector(state => state.user.users);
  const emojiPickerRef = useRef(null);
  const hasPermissionFor = useContext(UserPermissionsContext);
  const hasChatPermissions = () => hasPermissionFor(PERMISSIONS.chat);

  const [{ scale, }, emojiPickerSpring] = useSpring(() => ({
    scale: 0,
    config: {
      ...config.stiff,
      clamp: true,
      duration: 0,
    },
  }));

  const toggleEmojiPicker = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const input = messageTextFieldRef.current.querySelector('input');
    input.focus();
    setEmojiPickerOpen(!emojiPickerOpen);
  };

  useEffect(() => {
    emojiPickerSpring.start({
      scale: emojiPickerOpen ? 1 : 0,
    });
    if (emojiPickerOpen) {
      const closeEmojiPicker = (e) => {
        if (emojiPickerRef.current.contains(e.target)) {
          return;
        }
        setEmojiPickerOpen(false);
        window.removeEventListener('click', closeEmojiPicker);
      };
      window.addEventListener('click', closeEmojiPicker);
    }
  }, [emojiPickerOpen]);

  useEffect(() => {
    if (!messagesRef.current) {
      return;
    }
    const message = [...messagesRef.current.querySelectorAll('div')];
    if (messages.length <= 1) {
      return;
    }
    message[message.length - 1].scrollIntoView();
  }, [messages]);


  const openEditUserModal = () => {
    dispatch(setIsEditUserModalOpen(true));
  };

  const send = () => {
    const input = messageTextFieldRef.current.querySelector('input');
    if (input.value === '') {
      return;
    }
    dispatch(sendMessage(input.value));
    input.value = '';
  };

  const Message = ({ message }) => {
    const user = users.find(user => user.publicId === message.publicUserId);

    const renderTextMessage = (messageString) => {
      try {
        new URL(messageString);
      } catch (e) {
        return messageString;
      }
      return (<a href={messageString}
        target='_blank'
        rel='noreferrer'>
        {messageString}
      </a>);
    };

    return (
      <div className={styles.Message}>
        <span className={styles.Username}
          style={{ color: user?.color }}>
          {user?.name}:
        </span>
        {renderTextMessage(message.content)}
      </div>);
  };

  return (
    <div className={styles.Chat}>
      <div className={styles.Messages} ref={messagesRef}>
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
      </div>
      {isLargeScreen &&
        <div className={styles.EmojiPickerPosition}
          style={{ pointerEvents: emojiPickerOpen ? 'all' : 'none'}}
        >
          <div className={styles.EmojiPickerAbsoluteWrapper}
            ref={emojiPickerRef}>
            <animated.div style={{
              scale,
              height: '300px',
              width: '300px',
              transformOrigin: 'bottom right'
            }}>
              <EmojiPicker
                height='100%'
                width='100%'
                theme='dark'
                emojiStyle='native'
                lazyLoadEmojis={true}
                previewConfig={{
                  showPreview: false,
                }}
                onEmojiClick={(emojiObject, e) => {
                  const input = messageTextFieldRef.current.querySelector('input');
                  input.value += emojiObject.emoji;
                }}
              />
            </animated.div>
          </div>
        </div>
      }
      <TextField
        ref={messageTextFieldRef}
        disabled={!hasChatPermissions()}
        placeholder='Type a message...'
        autoComplete='off'
        endDecorator={
          isLargeScreen
            ? <SentimentSatisfiedRoundedIcon
              onClick={toggleEmojiPicker} />
            : <ChatRoundedIcon />
        }
        startDecorator={!hasChatPermissions()
          ? <span className={styles.NoAccessText}>Access denied!</span>
          : null
        }
        onKeyDown={e => {
          if (e.key !== 'Enter') {
            return;
          }
          send();
        }}
      />
      <div className={styles.BottomBar}>
        <div>
          <EmojiReactions />
        </div>
        <div className={styles.Right}>
          <Tooltip title={'Edit User'} placement="top">
            <SettingsSuggestRoundedIcon className={styles.Icon} onClick={openEditUserModal} />
          </Tooltip>
          <Tooltip title="Send" placement="top">
            <Button disabled={!hasChatPermissions()} onClick={send}>
              <SendRounded />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Chat;
