import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  query: '',
  results: null,
  searchBarOpen: false,
  resultsOpen: false,
  searchingForResults: false,
  searchKeywordResults: [],
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.query = action.payload;
    },
    setSearchResults: (state, action) => {
      state.results = action.payload;
    },
    setSearchBarOpen: (state, action) => {
      state.searchBarOpen = action.payload;
    },
    setSearchResultsOpen: (state, action) => {
      state.resultsOpen = action.payload;
    },
    setSearchingForResults: (state, action) => {
      state.searchingForResults = action.payload;
    },
    setSearchKeywordResults: (state, action) => {
      state.searchKeywordResults = action.payload;
    },
  },
});

export const {
  setSearchQuery,
  setSearchResults,
  setSearchBarOpen,
  setSearchResultsOpen,
  setSearchingForResults,
  setSearchKeywordResults,
} = searchSlice.actions;

export default searchSlice.reducer;
