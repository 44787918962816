import { setCurrentIndex } from './youtubeShortsSlice';

const youtubeShortsMessageHandler = (store, action, message) => {
    switch (message.action) {
        case 'CHANGE_CURRENT_INDEX':
            store.dispatch(setCurrentIndex(message.payload));
            break;
        default:
            console.warn('Unknown message action', message.action);
    }
};

export default youtubeShortsMessageHandler;
