import WelcomeScreen from './WelcomeScreen';
import PublicRoomsScreen from './PublicRoomsScreen';
import styles from './Home.module.css';
import { CreatePublicRoomModalProvider } from './CreatePublicRoomModalProvider';
import HowToScreen from './HowToScreen';
import { faqStructuredData } from '../faq/FAQ';

const Home = () => {
  return (
    <div className={styles.Home}>
      <script type='application/ld+json'>{JSON.stringify(faqStructuredData)}</script>
      <CreatePublicRoomModalProvider>
        <WelcomeScreen />
        <PublicRoomsScreen />
        <HowToScreen />
      </CreatePublicRoomModalProvider>
    </div>
  );
};

export default Home;
