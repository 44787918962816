import styles from './AccessControlListManager.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox } from '@mui/joy';
import { Fragment, useContext, useEffect, useState } from 'react';
import { updateRoomPermissions } from './accessControlListSlice';
import { PERMISSIONS, UserPermissionsContext } from './UserPermissionsProvider';

const AccessControlListManager = () => {
    const dispatch = useDispatch();
    const permissions = useSelector(state => state.accessControlList.roomPermissions);
    const [newPermissions, setNewPermissions] = useState([]);
    const hasPermissionFor = useContext(UserPermissionsContext);

    useEffect(() => {
        if (!permissions) {
            return;
        }
        setNewPermissions(permissions);
    }, [permissions]);

    const handlePermissionChange = (target, entry, role) => {
        setNewPermissions(newPermissions
            .map(object => ({ ...object }))
            .map(p => {
                if (p.permission === entry.permission) {
                    p[role] = target.checked;
                }
                return p;
            }));
    };

    const handleSubmit = () => {
        console.table(newPermissions);
        dispatch(updateRoomPermissions(newPermissions));
    };

    return (
        <div className={styles.AccessControlListManager}>
            <h2>Set Permissions</h2>
            <p>
                Manage permissions for users and moderators in this room.
            </p>
            <div className={styles.PermissionMatrix}>
                <div className={[styles.PermissionName, styles.Header].join(' ')}>
                    Permission
                </div>
                <div className={styles.Header}>Moderator</div>
                <div className={styles.Header}>User</div>
                {newPermissions?.map(entry =>
                    <Fragment key={entry.permission}>
                        <div className={styles.PermissionName} >
                            {entry.permission}
                        </div>
                        <div>
                            <Checkbox
                                disabled={!hasPermissionFor(PERMISSIONS.userManagement)}
                                checked={entry.moderator}
                                onChange={({ target }) => handlePermissionChange(target, entry, 'moderator')} />
                        </div>
                        <div>
                            <Checkbox
                                disabled={!hasPermissionFor(PERMISSIONS.userManagement)}
                                checked={entry.user}
                                onChange={({ target }) => handlePermissionChange(target, entry, 'user')} />
                        </div>
                    </Fragment>)}
            </div>
            <Button className={styles.Submit}
                onClick={handleSubmit}
                disabled={!hasPermissionFor(PERMISSIONS.userManagement)}>
                Save
            </Button>
        </div>
    );
};

export default AccessControlListManager;
