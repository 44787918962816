
import config from '../config';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchBarOpen, setSearchResultsOpen } from '../search/SearchSlice';
import { useMediaQuery } from 'react-responsive';
import Search from '../search/Search';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import { ReactComponent as Logo } from '../images/logo.svg';
import styles from './Header.module.css';
import { Alert, Button, IconButton, Tooltip, Typography } from '@mui/joy';
import { changeViewMode, setIsRoomSettingsOpen } from '../rooms/roomSlice';
import RoomSettings from '../rooms/RoomSettings';
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import ShareRoomQRCodeModal from '../qrcode/ShareRoomQRCodeModal';
import { useRef, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


const Header = () => {
  const dispatch = useDispatch();
  const searchBarOpen = useSelector((state) => state.search.searchBarOpen);
  const isRoomSettingsOpen = useSelector(state => state.room.isRoomSettingsOpen);
  const location = useLocation();
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const isMobilePortrait = useMediaQuery({
    query: '(orientation: portrait) and (max-width: 760px)'
  });
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1200px)'
  });
  const viewMode = useSelector((state) => state.room.viewMode);
  const actionsRef = useRef(null);
  const [shareAlertOpen, setShareAlertOpen] = useState(false);

  const toggleSearch = () => {
    if (searchBarOpen) {
      dispatch(setSearchBarOpen(false));
      dispatch(setSearchResultsOpen(false));
    } else {
      dispatch(setSearchBarOpen(true));
    }
  };

  const shareRoom = () => {
    if (navigator.share) {
      navigator.share({
        title: `Watch YouTube with me`,
        text: `Let's watch YouTube on flock together!`,
        url: `${config.baseUrl}${location.pathname}`
      });
    } else {
      navigator.clipboard.writeText(`${config.baseUrl}${location.pathname}`);
      setShareAlertOpen(true);
      setTimeout(() => {
        setShareAlertOpen(false);
      }, 4000);
    }
  };

  const toggleRoomSettings = () => {
    dispatch(setIsRoomSettingsOpen(!isRoomSettingsOpen));
  };

  return (
    <header className={styles.header}>
      <div className={styles.Left}>
        <Link to='/' className={styles.LogoLink}>
          <Logo />
          <h1>flock</h1>
        </Link>
        <div className={styles.ControlItems}>

        </div>
      </div>
      <div className={styles.ActionIcons} ref={actionsRef}>
        {location.pathname.startsWith('/room') &&
          (<>
            <Tooltip title={viewMode === 'YOUTUBE' ? 'Watch YouTube Shorts' : 'Go back to Videos'} placement="bottom">
              <Button className={styles.ActionButton}
                sx={{
                  backgroundColor: 'hsl(225deg 14% 20%)',
                  marginRight: '24px',
                }}
                onClick={() => {
                  if (viewMode === 'YOUTUBE') {
                    dispatch(changeViewMode('YOUTUBE_SHORTS'));
                  } else if (viewMode === 'YOUTUBE_SHORTS') {
                    dispatch(changeViewMode('YOUTUBE'));
                  }
                }}>
                Shorts
              </Button>
            </Tooltip>
            {isMobilePortrait
              ? (
                searchBarOpen
                  ? <SearchOffRoundedIcon style={{
                    fill: searchBarOpen ? 'var(--text-white)' : 'var(--text-grey-2)'
                  }} onClick={toggleSearch} />
                  : <SearchRoundedIcon style={{
                    fill: searchBarOpen ? 'var(--text-white)' : 'var(--text-grey-2)'
                  }} onClick={toggleSearch} />
              )
              : <Search />}
            {isLargeScreen &&
              <Tooltip title={'Create QR Code'} placement="bottom">
                <>
                  <QrCode2RoundedIcon onClick={() => {
                    setIsQRCodeModalOpen(true);
                  }} />
                  <ShareRoomQRCodeModal isOpen={isQRCodeModalOpen} close={() => setIsQRCodeModalOpen(false)} />
                </>
              </Tooltip>
            }
            <Tooltip title={'Share Room'} placement="bottom">
              <ShareRoundedIcon onClick={shareRoom} />
            </Tooltip>
           <Tooltip title={'Room Settings'} placement="bottom">
              <SettingsRoundedIcon onClick={toggleRoomSettings} />
            </Tooltip>
            <RoomSettings />
          </> )}
      </div>
      {shareAlertOpen &&
      <Alert
            sx={{
              position: 'fixed',
              top: 'var(--header-height)',
              right: '8px',
              zIndex: '1000',
              transform: 'translate(0px, 8px)',
              backgroundColor: 'var(--grey-header)',
              width: isLargeScreen ? actionsRef.current?.offsetWidth - 8 : window.innerWidth - 16,
            }}
            endDecorator={
              <IconButton variant='soft' size='sm'
                sx={{
                  'color': 'var(--text-white)',
                  'backgroundColor': 'var(--grey-header)',
                  '&:hover': {
                    backgroundColor: 'var(--grey-header)',
                    opacity: 0.8
                  }
                }}
                onClick={() => setShareAlertOpen(false)}>
                <CloseRoundedIcon />
              </IconButton>
            }>
            <div>
              <Typography fontWeight="lg" mt={0.25}> Copied Link to Clipboard!
              </Typography>
              <Typography fontSize="sm" sx={{ opacity: 0.8 }}>
                Share this link with your friends to watch together!
              </Typography>
          </div>
          </Alert>
      }
    </header>
  );
};

export default Header;
