import { ChangeVideoDTO, VideoDetails } from './roomSlice';

const mediaControlInterceptor = async (store, action, client) => {
  const baseDestination = `/app/room/${store.getState().room.roomId}/media-control`;
  const type = 'mediaControl';

  switch (action.type) {
    case 'room/play':
      client.publish({
        destination: `${baseDestination}/play`,
        body: JSON.stringify({
          type,
          action: 'PLAY',
          payload: action.payload,
        }),
      });
      break;
    case 'room/pause':
      client.publish({
        destination: `${baseDestination}/pause`,
        body: JSON.stringify({
          type,
          action: 'PAUSE',
          payload: action.payload,
        }),
      });
      break;
    case 'room/changeVideo':
      client.publish({
        destination: `${baseDestination}/setVideoId`,
        body: JSON.stringify({
          type,
          action: 'SET_VIDEO_ID',
          payload: action.payload
        }),
      });
      break;
    case 'room/changeViewMode':
      client.publish({
        destination: `${baseDestination}/changeViewMode`,
        body: JSON.stringify({
          type,
          action: 'CHANGE_VIEW_MODE',
          payload: action.payload,
        }),
      });
      break;
    case 'room/changePlaybackRate':
      client.publish({
        destination: `${baseDestination}/changePlaybackRate`,
        body: JSON.stringify({
          type,
          action: 'CHANGE_PLAYBACK_RATE',
          payload: action.payload,
        }),
      });
      break;
    case 'room/sendCurrentTimeToUser':
      if (!window.youtubePlayer) {
        break;
      }
      client.publish({
        destination: `/app/room/${store.getState().room.roomId}/user/${action.payload}/sendCurrentTime`,
        body: JSON.stringify({
          type,
          action: 'SEND_CURRENT_TIME_TO_USER',
          payload: await window.youtubePlayer.getCurrentTime(),
        }),
      });
      break;
    default:
      break;
  }
};

export default mediaControlInterceptor;
