import { Tab, TabList, TabPanel, Tabs, tabClasses, Badge } from '@mui/joy';
import Chat from '../chat/Chat';
import Playlist from '../playlist/Playlist';
import UserList from '../user/UserList';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import SearchResultsView from '../search/SearchResultsView';
import RelatedVideos from '../youtube/RelatedVideos';

import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './RoomTabsContainer.module.css';
import History from '../history/History';

const RoomTabsContainer = () => {
    const isMobilePortrait = useMediaQuery({
        query: '(orientation: portrait) and (max-width: 760px)'
    });
    const isMobileLandscape = useMediaQuery({ query: '(orientation: landscape)' });
    const isTabletLandscape = useMediaQuery({
        query: '(orientation: landscape) and (min-width: 800px) and (min-height: 650px)'
    });
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [badgeContents, setBadgeContents] = useState({ chat: 0, playlist: 0, users: 0 });
    const messages = useSelector(state => state.chat.messages);
    const [lastMessageAmount, setLastMessageAmount] = useState(0);

    const tabIndexMap = {
        chat: 0,
        playlist: 1,
        users: 2,
        history: 3,
        recommendations: 4
    };

    const handleTabChange = (_, newValue) => {
        if (newValue !== tabIndexMap.chat) {
            setLastMessageAmount(messages.length);
        }
        setBadgeContents({
            ...badgeContents,
            [Object.entries(tabIndexMap)
                .filter(e => e[1] === newValue)
                .map(e => e[0])[0]]: 0
        });
        setCurrentTabIndex(newValue);
    };

    useEffect(() => {
        if (currentTabIndex === tabIndexMap.chat) {
            return;
        }
        setBadgeContents({
            ...badgeContents,
            chat: messages.length - lastMessageAmount
        });
    }, [messages]);


    return (
        <div className={styles.Tabs}>
            {isMobileLandscape && <SearchResultsView />}
            <Tabs onChange={handleTabChange}
                defaultValue={0}
                className={styles.JoyTabs}>
                <TabList sx={{
                    background: 'var(--grey-dark-5)',
                    borderRadius: '0',
                    p: 0,
                    [`& .${tabClasses.root}`]: {
                        'bgcolor': 'transparent',
                        'color': 'var(--text-grey-2)',
                        'boxShadow': 'none',
                        'p': '16px',
                        'borderRadius': '0',
                        '&:hover': {
                            bgcolor: 'transparent',
                            borderRadius: '0',
                        },
                        [`&.${tabClasses.selected}`]: {
                            color: 'var(--text-white)',
                            bgcolor: 'var(--grey-dark-1)',
                            borderRadius: '0',
                        },
                    },
                }}>
                    <Tab className={styles.Tab} value={tabIndexMap.chat}>
                        <Badge className={styles.Tab} badgeInset='-14%' badgeContent={badgeContents.chat}>
                            <MessageRoundedIcon />
                            {isTabletLandscape && 'Chat'}
                        </Badge>
                    </Tab>
                    <Tab className={styles.Tab} value={tabIndexMap.playlist}>
                        <PlaylistPlayRoundedIcon />
                        {isTabletLandscape && 'Playlist'}
                    </Tab>
                    {((isMobilePortrait || isMobileLandscape) && !isTabletLandscape) &&
                        <Tab className={styles.Tab} value={tabIndexMap.users}>
                            <GroupRoundedIcon />
                            {isTabletLandscape && 'Users'}
                        </Tab>
                    }
                    <Tab className={styles.Tab} value={tabIndexMap.history}>
                        <HistoryRoundedIcon />
                        {isTabletLandscape && 'History'}
                    </Tab>
                    <Tab className={styles.Tab} value={tabIndexMap.recommendations}>
                        <TravelExploreRoundedIcon />
                    </Tab>
                </TabList>
                <TabPanel value={tabIndexMap.chat} className={styles.TabPanel}>
                    <Chat />
                </TabPanel>
                <TabPanel value={tabIndexMap.playlist} className={styles.TabPanel}>
                    <Playlist />
                </TabPanel>
                {(isMobilePortrait || isMobileLandscape) &&
                    <TabPanel value={tabIndexMap.users} className={styles.TabPanel}>
                        <UserList />
                    </TabPanel>
                }
                <TabPanel value={tabIndexMap.history} className={styles.TabPanel}>
                    <History />
                </TabPanel>
                <TabPanel value={tabIndexMap.recommendations} className={styles.TabPanel}>
                    <RelatedVideos />
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default RoomTabsContainer;
