import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const UserPermissionsContext = createContext();

const PERMISSIONS = {
    mediaControl: 'MEDIA_CONTROL',
    videoChat: 'VIDEO_CHAT',
    chat: 'CHAT',
    emojiReactions: 'EMOJI_REACTIONS',
    playlists: 'PLAYLISTS',
    userManagement: 'USER_MANAGEMENT',
};

const UserPermissionsProvider = ({ children }) => {
    const acl = useSelector(state => state.accessControlList.roomPermissions);
    const currentUser = useSelector(state => state.user.currentUser);
    const [userPermissions, setUserPermissions] = useState([]);

    useEffect(() => {
        if (!acl || !currentUser) {
            return;
        }

        const hasPermissionFor = (permission) => {
            // TODO user specific permissions
            if (currentUser.role === 'OWNER') {
                return true;
            } else if (currentUser.role === 'MODERATOR') {
                return acl.find(p => p.permission === permission)?.moderator;
            } else if (currentUser.role === 'USER') {
                return acl.find(p => p.permission === permission)?.user;
            } else {
                console.error('Invalid user role');
                return false;
            }
        };

        setUserPermissions(acl.map(p => {
            return {
                permission: p.permission,
                hasPermission: hasPermissionFor(p.permission),
            };
        }));
    }, [acl]);

    const hasPermissionFor = (permission) => {
        return userPermissions.find(p => p.permission === permission)?.hasPermission;
    };

    return (
        <UserPermissionsContext.Provider value={hasPermissionFor}>
            {children}
        </UserPermissionsContext.Provider>
    );
};

export { UserPermissionsContext, UserPermissionsProvider, PERMISSIONS };
