import { useDispatch } from 'react-redux';
import { sendEmojiReaction } from './emojiReactionsSlice';
import styles from './EmojiReactions.module.css';
import { useContext } from 'react';
import { PERMISSIONS, UserPermissionsContext } from '../access_control_list/UserPermissionsProvider';


const EmojiReactions = () => {
  const dispatch = useDispatch();
  const hasPermissionFor = useContext(UserPermissionsContext);
  const hasEmojiReactionsPermissions = () => hasPermissionFor(PERMISSIONS.emojiReactions);

  const handleEmojiClick = ({ target }) => {
    if (!hasEmojiReactionsPermissions()) {
      return;
    }
    const emoji = target.innerText;
    dispatch(sendEmojiReaction(emoji));
  };

  return (
    <div className={[
      styles.EmojiReactions,
      !hasEmojiReactionsPermissions() ? styles.Disabled : ''
    ].join(' ')}>
      {['😂', '👍', '🧐', '🍆', '🍑', '🖕', '❤️', '😢']
        .map((emoji, index) => (
          <div key={index}
            onClick={handleEmojiClick}
            className={styles.EmojiIcon}>
            {emoji}
          </div>
        ))}
    </div >
  );
};

export default EmojiReactions;
