import { ReactComponent as MonsterImage } from '../images/Flocks_Watch_TV.svg';
import styles from './HowToScreen.module.css';

const HowToScreen = () => {
    return (
        <div className={styles.HowToScreen}>
            <div className={styles.Header}>
                <div className={styles.HeaderText}>
                    <h1>
                        How to watch YouTube together on <span className={styles.RedText}>flock.video</span>
                    </h1>
                    <h2>
                        flock.video makes it easy to connect with others and watch
                        videos together in sync.
                    </h2>
                </div>
                <MonsterImage className={styles.MonsterImage} />
            </div>
            <div className={styles.Steps}>
                <div className={styles.Step}>
                    <div className={styles.Text}>
                        <h3>Create a Room 🪴</h3>
                        <div>
                            Create a private or public room, give it a name and
                            tags. Share link for private rooms, public rooms will
                            be displayed for anyone to join.
                        </div>
                    </div>
                </div>
                <div className={styles.Step}>
                    <div className={styles.Text}>
                        <h3>Watch Videos 📺</h3>
                        <div>
                            Search and play YouTube videos in sync with others in
                            the room. Add Videos to the Playlist or replay videos
                            from your History.
                        </div>
                    </div>
                </div>
                <div className={styles.Step}>
                    <div className={styles.Text}>
                        <h3>Chat and React 😍</h3>
                        <div>
                            Chat with other room members while watching videos and
                            use our built-in emoji reactions to express your feelings.
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default HowToScreen;
