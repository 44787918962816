import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  playlist: {
    videos: []
  },
};

export const playlistSlice = createSlice({
    name: 'playlist',
    initialState,
    reducers: {
        updatePlaylist: () => {
          return;
        },
        addToPlaylist: () => {
          return;
        },
        removeFromPlaylist: () => {
          return;
        },
        setPlaylist: (state, action) => {
          state.playlist = action.payload;
        },
    },
});

export const { updatePlaylist, setPlaylist, addToPlaylist, removeFromPlaylist } = playlistSlice.actions;

export default playlistSlice.reducer;
