import { setPlaybackRate, setCurrentTime, setIsPlaying, setVideoId, setInitialTime, setViewMode } from './roomSlice';

const socketMediaControlMessageHandler = (store, action, message) => {
  switch (message.action) {
    case 'PLAY':
      store.dispatch(setCurrentTime(message.payload));
      store.dispatch(setIsPlaying(true));
      break;
    case 'PAUSE':
      store.dispatch(setCurrentTime(message.payload));
      store.dispatch(setIsPlaying(false));
      break;
    case 'SET_VIDEO_ID':
      store.dispatch(setVideoId(message.payload));
      break;
    case 'CHANGE_PLAYBACK_RATE':
      store.dispatch(setPlaybackRate(Number.parseFloat(message.payload)));
      break;
    case 'SEND_CURRENT_TIME_TO_USER':
      // only relevant if video is currently playing and initial time has not been set yet
      if (!store.getState().room.isPlaying || store.getState().room.initialTime.time) {
        break;
      }
      store.dispatch(setInitialTime({
        time: message.payload,
        timeReceived: Date.now(),
        initialized: false
      }));
      break;
    case 'CHANGE_VIEW_MODE':
      store.dispatch(setViewMode(message.payload));
      break;
    default:
      console.warn('Unknown message type', message.type);
  }
};

export default socketMediaControlMessageHandler;
