import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import config from '../config';
import { changeVideo } from '../rooms/roomSlice';
import styles from './SuggestedVideos.module.css';

const SuggestedVideos = () => {
    const dispatch = useDispatch();
    const [feed, setFeed] = useState([]);

    const play = (videoId) => {
        dispatch(changeVideo(videoId));
    };

    useEffect(() => {
        fetch(`${config.youtubeSearchApiEndpoint}/suggestions?amount=4`)
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error('Failed to fetch video details');
                }
                return response.json();
            })
            .then((data) => {
                setFeed(data.items.map(video => ({
                    id: video.id,
                    title: video.title,
                    channel: video.channelTitle,
                    thumbnail: video.thumbnail.thumbnails[0].url,
                })));
            });
    }, []);
    return (
        <div className={styles.SuggestedVideos}>
            {feed.map(video => (
                <div key={video.id}
                    className={styles.Video}
                    onClick={ () => play(video.id)}>
                    <img alt={video.title} src={video.thumbnail} />
                    <div className={styles.Title}>{video.title}</div>
                    <div className={styles.Channel}>{video.channel}</div>
                </div>
            ))}
        </div>
    );
};

export default SuggestedVideos;
