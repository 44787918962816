import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        sendMessage: (state, action) => {
            return;
        },
        receiveMessage: (state, action) => {
            state.messages.push(action.payload);
        },
        setMessages: (state, action) => {
            state.messages = action.payload;
        }
    },
});

export const { sendMessage, receiveMessage, setMessages } = chatSlice.actions;

export default chatSlice.reducer;
