import { sendCurrentTimeToUser } from '../rooms/roomSlice';
import { setUsers } from './userSlice';

const userMessageHandler = (store, action, message) => {
    switch (message.action) {
        case 'UPDATE_USERS':
            // if a new user joins the room, send the current time to that user
            const oldUsers = store.getState().user.users
                .filter(user => user.online);
            const newUsers = message.payload
                .filter(user => user.online)
                .filter((user) => !oldUsers.find((u) => u.publicId === user.publicId));
            if (newUsers[0] && store.getState().user.currentUser
                && newUsers[0].publicId !== store.getState().user.currentUser.publicId) {
                store.dispatch(sendCurrentTimeToUser(newUsers[0].publicId));
            }

            store.dispatch(setUsers(message.payload));
            break;
        default:
            console.warn('Unknown message action', message.action);
    }
};

export default userMessageHandler;
