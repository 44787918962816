import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ids: [],
    currentIndex: 0,
};

export const youtubeShortsSlice = createSlice({
    name: 'youtubeShorts',
    initialState,
    reducers: {
        setIds: (state, action) => {
            state.ids = action.payload;
        },
        setCurrentIndex: (state, action) => {
            state.currentIndex = action.payload;
        },
        changeCurrentIndex: (state, action) => {
            return;
        },
    },
});

export const { setIds, setCurrentIndex, changeCurrentIndex } = youtubeShortsSlice.actions;

export default youtubeShortsSlice.reducer;
