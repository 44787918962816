import Button from '@mui/joy/Button';
import UniqueId from 'short-unique-id';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlayIcon } from '../images/playIcon.svg';
import { ReactComponent as Logo } from '../images/Hero_Illustration.svg';
import styles from './WelcomeScreen.module.css';
import { useContext } from 'react';
import { CreatePublicRoomModalContext } from './CreatePublicRoomModalProvider';

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const setCreatePublicRoomModalOpen = useContext(CreatePublicRoomModalContext);

  const createPrivateRoom = () => {
    console.debug('private room');
    const roomId = new UniqueId().randomUUID(10);
    navigate(`/room/${roomId}`);
  };

  return (
    <div className={styles.WelcomeScreen}>
      <div className={styles.LeftSide}>
        <h1>Watch YouTube Together</h1>
        <h2>Watch YouTube videos together in sync. With friends or with strangers.</h2>
        <div className={styles.CreateRoomSection}>
          <h3>Create Room</h3>
          <div className={styles.CreateRoomButtons}>
            <Button className={styles.CreateButton} onClick={() => setCreatePublicRoomModalOpen(true)}>Public Room</Button>
            <Button className={styles.CreateButton} onClick={createPrivateRoom}>Private Room</Button>
          </div>
          <div className={styles.FormDivider}>
            <div>or</div>
          </div>
          <Button sx={{ bgcolor: 'grey.header' }}
            className={styles.JoinButton}
            onClick={(() => {
              document.querySelector('.public-rooms')
                .scrollIntoView({ behavior: 'smooth' });
            })}>
            Join a public room
          </Button>
        </div>
      </div>
      <div className={styles.LogoRight}>
        <Logo />
      </div>
      <div className={styles.Backdrops}>
        <PlayIcon className={styles.BackdropIcon} />
        <PlayIcon className={styles.BackdropIcon} />
        <PlayIcon className={styles.BackdropIcon} />
      </div>
    </div>
  );
};

export default WelcomeScreen;
