import { Button, CircularProgress, TextField, Tooltip } from '@mui/joy';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeVideo, changeViewMode, setViewMode } from '../rooms/roomSlice';
import PlaylistRemoveRoundedIcon from '@mui/icons-material/PlaylistRemoveRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import styles from './Playlist.module.css';
import { addToPlaylist, removeFromPlaylist, updatePlaylist } from './playlistSlice';
import config from '../config';
import youtubeUrlParser from '../youtube/youtubeUrlParser';
import { PERMISSIONS, UserPermissionsContext } from '../access_control_list/UserPermissionsProvider';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

const Playlist = () => {
  const dispatch = useDispatch();
  const sharedPlaylist = useSelector((state) => state.playlist.playlist);
  const [localPlaylist, setLocalPlaylist] = useState({ videos: [] });
  const urlInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const hasPermissionFor = useContext(UserPermissionsContext);
  const viewMode = useSelector((state) => state.room.viewMode);
  const hasMediaControlPermissions = () => hasPermissionFor(PERMISSIONS.mediaControl);


  useEffect(() => {
    setLocalPlaylist(sharedPlaylist);
  }, [sharedPlaylist]);

  const handleKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }
    handleUrlSubmit();
  };

  const handleUrlSubmit = async () => {
    const value = urlInputRef.current.querySelector('input').value;
    if (value === null) {
      return;
    }
    try {
      const videoId = youtubeUrlParser.parse(value);
      setLoading(true);
      await fetch(`${config.youtubeSearchApiEndpoint}/video-details?videoId=${videoId}`)
        .then((response) => response.json())
        .then((data) => {
          dispatch(addToPlaylist({
            id: videoId,
            title: data.title,
            channel: data.channel,
          }));
        });
    } catch (e) {
      console.debug('Invalid YouTube URL', e);
    }
    urlInputRef.current.querySelector('input').value = '';
    setLoading(false);
  };

  const moveUp = (item) => {
    const index = localPlaylist.videos.indexOf(item);
    const videos = [...localPlaylist.videos];
    if (index === 0) {
      videos.splice(index, 1);
      videos.push(item);
      dispatch(updatePlaylist({ videos }));
      return;
    }
    videos.splice(index, 1);
    videos.splice(index - 1, 0, item);
    dispatch(updatePlaylist({ videos }));
  };

  const moveDown = (item) => {
    const index = localPlaylist.videos.indexOf(item);
    const videos = [...localPlaylist.videos];
    if (index === videos.length - 1) {
      videos.splice(index, 1);
      videos.unshift(item);
      dispatch(updatePlaylist({ videos }));
      return;
    }
    videos.splice(index, 1);
    videos.splice(index + 1, 0, item);
    dispatch(updatePlaylist({ videos }));
  };

  return (
    <div className={styles.Playlist}>
      <h2>Playlist</h2>
      {hasMediaControlPermissions() &&
        <div className={styles.AddByUrl}>
          <h3>Add by YouTube Url</h3>
          <div className={styles.InputWrapper}>
            <TextField ref={urlInputRef}
              fullWidth={true}
              placeholder="Paste YouTube url here..."
              onKeyDown={handleKeyDown} />
            <Tooltip title="Add to playlist" placement="bottom">
              <Button onClick={handleUrlSubmit}
                sx={{
                  backgroundColor: 'var(--grey-input)',
                  height: '40px',
                }}>
                {!loading
                  ? <PlaylistAddRoundedIcon />
                  : <CircularProgress />
                }
              </Button>
            </Tooltip>
          </div>
        </div>}
      {localPlaylist?.videos.length > 0 &&
        <h3>Up next</h3>
      }
      <div className={styles.Entries}>
        {localPlaylist?.videos?.map((item) => (
          <div key={item.shortId} className={styles.Entry}>
            <div className={styles.MoveIcons}>
              {hasMediaControlPermissions() && <>
                <Tooltip title="Move up" placement="left">
                  <KeyboardArrowUpRoundedIcon onClick={() => moveUp(item)} />
                </Tooltip>
                <Tooltip title="Move down" placement="left">
                  <KeyboardArrowDownRoundedIcon onClick={() => moveDown(item)} />
                </Tooltip>
                {/* <DragHandleRoundedIcon className={styles.DragIcon} /> */}
              </>
              }
            </div>
            <div className={styles.Thumbnail}>
              <img src={item.thumbnail || `https://i.ytimg.com/vi_webp/${item.id}/hqdefault.webp`} alt={item.title} />
            </div>
            <div className={styles.Description}>
              <div className={styles.Title}>
                <Tooltip title={item.title || ''} placement="right">
                  <span>{item.title} </span>
                </Tooltip>
              </div>
              <div className={styles.Channel}> {item.channel} </div>
            </div>
            {hasMediaControlPermissions() &&
              <div className={styles.Actions}>
                <Tooltip title="Remove from playlist" placement="bottom">
                  <Button
                    sx={{
                      padding: '4px',
                      backgroundColor: 'var(--grey-input)',
                    }}
                    onClick={() => dispatch(removeFromPlaylist(item.shortId))}>
                    <PlaylistRemoveRoundedIcon className={styles.ActionIcon} />
                  </Button>
                </Tooltip>
                <Tooltip title="Play Now" placement="bottom">
                  <Button
                    sx={{
                      padding: '4px',
                      backgroundColor: 'var(--grey-input)',
                    }}
                    onClick={() => {
                      let setOffMs = 0;
                      if (viewMode !== 'YOUTUBE') {
                        dispatch(changeViewMode('YOUTUBE'));
                        setOffMs = 10;
                      }
                      setTimeout(() => {
                        dispatch(removeFromPlaylist(item.shortId));
                      }, setOffMs);
                      setTimeout(() => {
                        dispatch(changeVideo(item.id));
                      }, setOffMs + 10);
                    }}>
                    <PlayArrowRoundedIcon className={styles.ActionIcon} />
                  </Button>
                </Tooltip>
              </div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Playlist;
