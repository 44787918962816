const EmojiReactionsInterceptor = (store, action, client) => {
    const baseDestination = `/app/room/${store.getState().room.roomId}/emoji-reaction`;
    const type = 'emojiReactions';

    switch (action.type) {
        case 'emojiReactions/sendEmojiReaction':
            client.publish({
                destination: `${baseDestination}/send`,
                body: JSON.stringify({
                    type,
                    action: 'SEND_EMOJI_REACTION',
                    emoji: action.payload,
                }),
            });
            break;
        default:
            break;
    }
};

export default EmojiReactionsInterceptor;
