import { useDispatch, useSelector } from 'react-redux';
import styles from './SearchResultsView.module.css';
import { useSpring, animated } from '@react-spring/web';
import { useEffect } from 'react';
import { Button, Tooltip } from '@mui/joy';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import { addToPlaylist } from '../playlist/playlistSlice';
import { changeVideo, changeViewMode } from '../rooms/roomSlice';
import { setSearchBarOpen, setSearchResults, setSearchResultsOpen } from './SearchSlice';
import { useMediaQuery } from 'react-responsive';
import config from '../config';

const SearchResultsView = () => {
  const dispatch = useDispatch();
  const searchResults = useSelector(state => state.search.results);
  const searchResultsOpen = useSelector(state => state.search.resultsOpen);
  const isMobilePortrait = useMediaQuery({
    query: '(orientation: portrait) and (max-width: 760px)'
  });
  const isMobileLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  const isTabletPortrait = useMediaQuery({
    query: '(orientation: portrait) and (min-width: 760px)',
  });
  const viewMode = useSelector((state) => state.room.viewMode);

  const [{ height }, spring] = useSpring(() => ({ height: '0px' }));

  useEffect(() => {
    if (!searchResults) {
      return;
    }
    dispatch(setSearchResultsOpen(true));
  }, [searchResults]);

  useEffect(() => {
    let expandedHeight;

    if (isMobilePortrait) {
      expandedHeight = (window.innerHeight / 2) + 'px';
    } else if (isMobileLandscape) {
      expandedHeight = (window.innerHeight - 70) + 'px';
    } else if (isTabletPortrait) {
      expandedHeight = (window.innerHeight / 2 + 50) + 'px';
    }

    spring.start({
      height: searchResultsOpen ? expandedHeight : '0px'
    });
  }, [searchResultsOpen]);

  const fetchNextPage = () => {
    fetch(`${config.youtubeSearchApiEndpoint}/next-page`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchResults.nextPage),
    })
      .then((response) => {
        if (!response.ok) {
          return [];
        }
        return response.json();
      })
      .then(({ items, nextPage }) => {
        dispatch(setSearchResults({
          ...searchResults,
          items: [...searchResults.items, ...items],
          nextPage,
        }));
      });
  };

  return (
    <animated.div className={styles.SearchResultsView} style={{ height }}>
      {!isMobilePortrait && (
        <div className={styles.CloseButton}>
          <Button onClick={() => {
            dispatch(setSearchResultsOpen(false));
          }}
            color='primary'
            startDecorator={<SearchOffRoundedIcon />}>
            Close Search
          </Button>
        </div>
      )}
      <div className={styles.Results}>
        {searchResults && searchResults.items.map((result, index) => (
          <div key={index} className={styles.SearchResult}>
            <div className={styles.Thumbnail}>
              <img
                src={result.thumbnail.thumbnails[0].url}
                alt={result.title} />
            </div>
            <div className={styles.Description} >
              <div className={styles.Title} >
                <Tooltip title={result.title || ''} placement="right">
                  <span> {result.title} </span>
                </Tooltip>
              </div>
              <div className={styles.Channel} >
                {result.channelTitle}
              </div>
            </div>
            <div className={styles.Actions}>
              <Button onClick={() => {
                dispatch(addToPlaylist({
                  id: result.id,
                  title: result.title,
                  thumbnail: result.thumbnail.thumbnails[0].url,
                  channel: result.channelTitle,
                  duration: result.length.simpleText,
                },
                ));
              }}
                sx={{
                  padding: '4px',
                  backgroundColor: '#393d45',
                }}>
                <PlaylistAddRoundedIcon className={styles.ActionIcon} />
              </Button>
              <Button onClick={() => {
                let setOffMs = 0;
                if (viewMode !== 'YOUTUBE') {
                  dispatch(changeViewMode('YOUTUBE'));
                  setOffMs = 10;
                }
                setTimeout(() => {
                  dispatch(changeVideo(result.id));
                }, setOffMs);
                dispatch(setSearchResultsOpen(false));
                dispatch(setSearchBarOpen(false));
              }}
                sx={{
                  padding: '4px',
                  backgroundColor: '#393d45',
                }}>
                <PlayArrowRoundedIcon className={styles.ActionIcon} />
              </Button>
            </div>
          </div>
        ))}
      </div>
      <Button onClick={fetchNextPage} color='primary'>
        Load More
      </Button>
    </animated.div >
  );
};

export default SearchResultsView;
