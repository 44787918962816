import { setRoomPermissions } from './accessControlListSlice';

const accessControlListMessageHandler = (store, action, message) => {
    switch (message.action) {
        case 'UPDATE_ROOM_PERMISSIONS':
            store.dispatch(setRoomPermissions(message.payload.entries));
            break;
        default:
            console.warn('Unknown message action', message.action);
    }
};

export default accessControlListMessageHandler;
