import { Button, Chip, ChipDelete, Modal, ModalDialog, Stack, TextField } from '@mui/joy';
import { createContext } from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UniqueId from 'short-unique-id';
import TagRoundedIcon from '@mui/icons-material/TagRounded';
import styles from './WelcomeScreen.module.css';


const CreatePublicRoomModalContext = createContext();

const CreatePublicRoomModalProvider = ({ children }) => {
    const navigate = useNavigate();
    const [createPublicRoomModalOpen, setCreatePublicRoomModalOpen] = useState(false);
    const tagInputRef = useRef(null);
    const [roomInitData, setRoomInitData] = useState({
        tags: []
    });

    const updateRoomInitData = ({ target }) => {
        setRoomInitData({
            ...roomInitData,
            [target.name]: target.value
        });
    };

    const handleRoomNameEnter = (e) => {
        if (e.key !== 'Enter') {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        tagInputRef.current.querySelector('input').focus();
    };

    const addTagFromInput = (e) => {
        if (e.key !== 'Enter' || e.target.value === '') {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        const tags = roomInitData.tags;

        if (tags.includes(e.target.value)) {
            return;
        }

        tags.push(e.target.value);
        setRoomInitData({
            ...roomInitData,
            tags
        });
        e.target.value = '';
    };

    const deleteTag = (e) => {
        const tag = e.target.closest('.JoyChip-root').textContent;
        const tags = roomInitData.tags.filter((t) => t !== tag);
        setRoomInitData({
            ...roomInitData,
            tags
        });
    };
    const handlePublicRoomSubmit = (event) => {
        console.debug(roomInitData);
        event.preventDefault();
        createPublicRoom();
        setCreatePublicRoomModalOpen(false);
    };

    const createPublicRoom = () => {
        console.debug('create public room');
        const roomId = new UniqueId().randomUUID(10);
        navigate(`/room/${roomId}`, {
            state: {
                public: true,
                roomName: roomInitData.roomName,
                tags: roomInitData.tags
            }
        });
    };
    return (
        <CreatePublicRoomModalContext.Provider value={setCreatePublicRoomModalOpen}>
            {children}
            <Modal open={createPublicRoomModalOpen} onClose={() => setCreatePublicRoomModalOpen(false)}>
                <ModalDialog
                    aria-labelledby="Create public room"
                    aria-describedby="Provide room name and tags for discovery"
                    sx={{
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'none',
                        border: 'none'
                    }}>
                    <h2>Create new public room</h2>
                    <p>Give your room a name, so it can be discovered by others.</p>
                    <form onSubmit={handlePublicRoomSubmit} >
                        <Stack spacing={3}>
                            <TextField onChange={updateRoomInitData}
                                name="roomName"
                                label="Name"
                                placeholder='Room name'
                                onKeyDown={handleRoomNameEnter}
                                required
                                autoFocus />
                            <TextField onKeyDown={addTagFromInput}
                                ref={tagInputRef}
                                name="tags"
                                label="Tags"
                                placeholder='tags'
                                startDecorator={<TagRoundedIcon />}
                            />
                            <div className={styles.TagContainer}>
                                {roomInitData.tags.map((tag, index) => (
                                    <Chip key={index}
                                        endDecorator={<ChipDelete onClick={deleteTag} />} >
                                        {tag}
                                    </Chip>
                                ))}
                            </div>
                            <p className={styles.AgreementText}>
                                <span className={styles.RequiredAsterix}>*</span> By creating a room you agree to our <a target='_blank' href='/termsofservice'>
                                    Terms of Service
                                </a><br /> and <a target='_blank' href='/privacy'>
                                    Privacy Policy
                                </a>.
                            </p>
                            <Button type='submit'>Create public room</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </CreatePublicRoomModalContext.Provider>
    );
};

export { CreatePublicRoomModalContext, CreatePublicRoomModalProvider };
