import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from '@react-spring/web';

import styles from './askForCookies.module.css';
import { Button } from '@mui/joy';


const AskForCookies = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const [{ y, opacity, shimOpacity }, spring] = useSpring(() => ({
    config: config.gentle,
    y: '100%',
    opacity: 0,
    shimOpacity: 0,
  }));

  useEffect(() => {
    const acceptedFromLocalStorage = localStorage.getItem('acceptedCookies');
    if (acceptedFromLocalStorage && acceptedFromLocalStorage === 'true') {
      setAccepted(true);
    } else {
      setTimeout(() => {
        setIsOpen(true);
      }, 3500);
    }
  }, []);

  const accept = () => {
    setAccepted(true);
    setIsOpen(false);
    localStorage.setItem('acceptedCookies', true);
  };

  const decline = () => {
    setAccepted(false);
    setIsOpen(false);
    localStorage.setItem('acceptedCookies', false);
  };

  useEffect(() => {
    spring.start({
      y: isOpen ? '0' : '100%',
      opacity: isOpen ? 1 : 0,
      shimOpacity: isOpen ? 0.8 : 0
    });
  }, [isOpen]);

  return (
    <div>
      <animated.div className={styles.Shim} style={{
        opacity: shimOpacity,
        pointerEvents: isOpen ? 'all' : 'none'
      }} />
      <animated.div className={styles.AcceptCookies} style={{ y, opacity }}>
        <div className={styles.Text}>
          <h1>Cookie Time 🍪</h1>
          <p>
            flock.video uses cookies to ensure you get the best experience to
            watch YouTube videos together with your friends.
            <br></br>
            <br></br>
            Check the <a target='_blank' href='/termsofservice'>
              Terms of Service
            </a><br /> and <a target='_blank' href='/privacy'>
              Privacy Policy
            </a> for more information.
          </p>
        </div>
        <div className={styles.Buttons}>
          <Button size='lg' className={[styles.Button].join(' ')} onClick={accept}>
            Accept
          </Button>
        </div>
      </animated.div>
    </div>
  );
};


export default AskForCookies;
