import styles from './Search.module.css';
import Autocomplete from '@mui/joy/Autocomplete';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import config from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { changeVideo, changeViewMode } from '../rooms/roomSlice';
import { setSearchingForResults, setSearchKeywordResults, setSearchQuery, setSearchResults } from './SearchSlice';
import { useSpring, animated } from '@react-spring/web';
import { useContext, useEffect } from 'react';
import { CircularProgress, Tooltip } from '@mui/joy';
import { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import youtubeUrlParser from '../youtube/youtubeUrlParser';
import { UserPermissionsContext, PERMISSIONS } from '../access_control_list/UserPermissionsProvider';


const Search = () => {
  const dispatch = useDispatch();
  const searchKeywordResults = useSelector((state) => state.search.searchKeywordResults);
  const searchBarOpen = useSelector((state) => state.search.searchBarOpen);
  const searchingForResults = useSelector((state) => state.search.searchingForResults);
  const searchInput = useRef(null);
  const hasPermissionFor = useContext(UserPermissionsContext);
  const isMobilePortrait = useMediaQuery({
    query: '(orientation: portrait) and (max-width: 760px)'
  });
  const [{ height }, spring] = useSpring(() => ({
    height: '0px',
  }));

  useEffect(() => {
    if (!isMobilePortrait) {
      return;
    }
    spring.start({
      height: searchBarOpen ? '50px' : '0px',
    });
    if (searchBarOpen) {
      searchInput.current.querySelector('input').focus();
    }
  }, [searchBarOpen]);

  const inputChangeHandler = ({ target }) => {
    if (target.value === 0 || target.value.length < 1) {
      return;
    }
    try {
      new URL(target.value);
      dispatch(setSearchKeywordResults([target.value]));
      return;
    } catch (e) {
      // Not a URL
    }
    fetch(`${config.serverUrl}/youtube/search/suggestions?query=${target.value}`)
      .then((response) => {
        if (!response.ok) {
          return [];
        }
        return response.json();
      })
      .then((data) => {
        dispatch(setSearchKeywordResults([
          ...data.filter((item) => item !== target.value)
            .filter((item) => typeof item === 'string')
        ]));
      });
  };

  const valueChangeHandler = (event, value) => {
    if (value === null) {
      return;
    }
    try {
      const videoId = youtubeUrlParser.parse(value);
      dispatch(changeViewMode('YOUTUBE'));
      dispatch(changeVideo(videoId));
    } catch (e) {
      console.debug('q is not a URL');
    }
    dispatch(setSearchQuery(value));
    dispatch(setSearchingForResults(true));
    fetch(`${config.youtubeSearchApiEndpoint}/search?q=${value}&maxResults=10`)
      .then((response) => {
        if (!response.ok) {
          dispatch(setSearchingForResults(false));
          return [];
        }
        return response.json();
      })
      .then((data) => {
        if (data.items.length === 0) {
          return;
        }
        dispatch(setSearchResults(data));
        dispatch(setSearchingForResults(false));
        dispatch(setSearchKeywordResults([]));
      });
  };

  return !hasPermissionFor(PERMISSIONS.mediaControl) ? null : (
    <animated.div className={styles.Search}
      style={{ height: !isMobilePortrait ? 'auto' : height }}>
      <Autocomplete
        ref={searchInput}
        className={styles.SearchInput}
        placeholder='Search or Enter YouTube Url'
        options={searchKeywordResults}
        freeSolo={true}
        endDecorator={
          searchingForResults
            ? <CircularProgress size='sm' />
            : <Tooltip title="Search" placement="bottom">
              <SearchRoundedIcon className={styles.SearchIcon}
                onClick={() => {
                  valueChangeHandler(null, searchInput.current.querySelector('input').value);
                }} />
            </Tooltip>
        }
        clearOnEscape={true}
        disableClearable={true}
        onInputChange={inputChangeHandler}
        onChange={valueChangeHandler}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            valueChangeHandler(e, e.target.value);
          }
        }}
      />
      <div className={styles.searchResults} style={{ display: 'none' }}>
      </div>
    </animated.div>
  );
};

export default Search;
