import pageStyles from '../page/CommonPage.module.css';
import faqStyles from './FAQ.module.css';

const faqStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': [
        {
            '@type': 'Question',
            'name': 'How to watch YouTube videos together?',
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'To watch YouTube videos together on flock.video, create a room, share the link with your friends, and start watching videos together in sync. You can also join public rooms and watch videos with other users. The platform allows to synchronize videos, so everyone can watch the same video at the same time and interact with other users.'
            }
        },
        {
            '@type': 'Question',
            'name': 'What is flock.video?',
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'flock.video is an innovative platform that allows you to watch YouTube videos in sync with friends or strangers without the need of creating an account or login. Whether you\'re looking to study together, do sports together, or simply watch someone react to different videos, flock.video makes it easy to connect with others who share your interests.'
            }
        },
        {
            '@type': 'Question',
            'name': 'Why is flock.video better than other platforms?',
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'flock.video offers a number of advantages over other platforms, including its better look and feel, less ads, public rooms, and active development team. With flock.video, you can watch videos without distractions and join public rooms to connect with new people and share your video watching experience. The platform is designed to be user-friendly and intuitive, so you can start enjoying the benefits of flock.video right away.'
            }
        },
        {
            '@type': 'Question',
            'name': 'How does flock.video work?',
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'flock.video works by allowing you to create a room, share the link with your friends and start watching videos together in sync. You can also join public rooms to connect with new people and share your video watching experience. The platform allows to synchronize videos, so everyone can watch the same video at the same time and interact with other users, making it a perfect place to watch videos together.'
            }
        },
        {
            '@type': 'Question',
            'name': 'Is there a limit to the number of people that can join a room?',
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'There is no limit to the number of people that can join a room on flock.video.'
            }
        },
        {
            '@type': 'Question',
            'name': 'Do I need to create an account or login for any feature?',
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'No, you don\t need to create an account or login for any feature on flock.video.'
            }
        },
        {
            '@type': 'Question',
            'name': 'Is flock.video free to use?',
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'Yes, flock.video is completely free to use.'
            }
        },
        {
            '@type': 'Question',
            'name': 'How can I contact the flock.video support team?',
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': 'You can reach out to our support team by email at hello@paperwave.xyz or through our social media accounts on [Twitter/Facebook/Instagram]. Our team is dedicated to making your experience on flock.video as enjoyable as possible, and we\'re always happy to hear from our users.'
            }
        }]
};

const FAQ = () => {
    return (
        <div className={pageStyles.Page}>
            <script type='application/ld+json'>{JSON.stringify(faqStructuredData)}</script>
            <h1>FAQ</h1>
            <h2 className={faqStyles.Question}>How to watch YouTube videos together?</h2>
            <p className={faqStyles.Answer}>
                To watch YouTube videos together on flock.video, create a room, share the link with your friends, and start watching videos together in sync. You can also join public rooms and watch videos with other users. The platform allows to synchronize videos, so everyone can watch the same video at the same time and interact with other users.
            </p>
            <h2 className={faqStyles.Question}>What is flock.video?</h2>
            <p className={faqStyles.Answer}>
                flock.video is an innovative platform that allows you to watch YouTube videos in sync with friends or strangers without the need of creating an account or login. Whether you're looking to study together, do sports together, or simply watch someone react to different videos, flock.video makes it easy to connect with others who share your interests.
            </p>
            <h2 className={faqStyles.Question}>Why is flock.video better than other platforms?</h2>
            <p className={faqStyles.Answer}>
                flock.video offers a number of advantages over other platforms, including its better look and feel, less ads, public rooms, native YouTube player and active development team. With flock.video, you can watch videos without distractions and join public rooms to connect with new people and share your video watching experience. The platform is designed to be user-friendly and intuitive, so you can start enjoying the benefits of flock.video right away.
            </p>
            <h2 className={faqStyles.Question}>How does flock.video work?</h2>
            <p className={faqStyles.Answer}>
                flock.video works by allowing you to create a room, share the link with your friends and start watching videos together in sync. You can also join public rooms to connect with new people and share your video watching experience. The platform allows to synchronize videos, so everyone can watch the same video at the same time and interact with other users, making it a perfect place to watch videos together.
            </p>
            <h2 className={faqStyles.Question}>Is there a limit to the number of people that can join a room?</h2>
            <p className={faqStyles.Answer}>
                There is no limit to the number of people that can join a room on flock.video.
            </p>
            <h2 className={faqStyles.Question}>Do I need to create an account or login for any feature?</h2>
            <p className={faqStyles.Answer}>
                No, you don't need to create an account or login for any feature on flock.video.
            </p>
            <h2 className={faqStyles.Question}>Is flock.video free to use?</h2>
            <p className={faqStyles.Answer}>
                Yes, flock.video is completely free to use.
            </p>
            <h2 className={faqStyles.Question}>How can I contact the flock.video support team?</h2>
            <p className={faqStyles.Answer}>
                You can reach out to our support team by email at hello@paperwave.xyz or through our social media accounts on Twitter/Facebook/Instagram. Our team is dedicated to making your experience on flock.video as enjoyable as possible, and we're always happy to hear from our users.
            </p>
        </div>);
};

export { faqStructuredData };
export default FAQ;
