import { Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';

import config from '../config';
import { setCurrentTime, setIsLoading as setIsRoomLoading, setIsPlaying, setIsWebSocketConnected, setPlaybackRate, setRoomName, setTags, setVideoId, setViewMode } from '../rooms/roomSlice';
import socketMessageHandlers from './socketMessageHandlers';
import socketInterceptors from './socketInterceptors';
import { setMessages } from '../chat/chatSlice';
import { setPlaylist } from '../playlist/playlistSlice';
import { setCurrentUser, setUsers } from '../user/userSlice';
import { setHistory } from '../history/historySlice';
import { setRoomPermissions } from '../access_control_list/accessControlListSlice';
import { setCurrentIndex as setCurrentYouTubeShortsIndex, setIds as setYouTubeShortsIds } from '../youtube_shorts/youtubeShortsSlice';

const socketMiddleWare = (store) => {
  const user = localStorage.getItem('user');
  let stompUsername = null;
  let connectHeaders = null;
  if (user) {
    connectHeaders = { user };
  }
  const client = new Client({
    debug: (message) => {
      // console.debug(message);
    },
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
    connectHeaders,
  });

  let subscribedToSelf = false;

  client.webSocketFactory = () => {
    return new SockJS(`${config.serverUrl}${config.stompEndpoint}`);
  };

  client.onConnect = (frame) => {
    console.debug('connected to Stomp', frame);
    const roomId = window.location.pathname.split('/').reverse()[0];
    stompUsername = frame.headers['user-name'];
    // this is for private stuff like private messages (noone else knows your stomp username)
    client.subscribe(`/topic/room/${roomId}/user/${stompUsername}`, (message) =>
      subscriptionMessageHandler(store, message.action || null, message));

    client.subscribe(`/topic/room/${roomId}`, (message) =>
      subscriptionMessageHandler(store, message.action || null, message));

    const currentUser = store.getState().user?.currentUser;
    if (currentUser) {
      // this is for webRTC signaling (everyone knows your publicId)
      client.subscribe(`/topic/room/${roomId}/user/${currentUser.publicId}`, (message) =>
        subscriptionMessageHandler(store, message.action || null, message));
    }

    store.dispatch(setIsWebSocketConnected(true));
  };

  client.onDisconnect = (frame) => {
    console.debug('disconnected from Stomp', frame);
    store.dispatch(setIsWebSocketConnected(false));
  };

  client.onStompError = (frame) => {
    console.debug('error from Stomp', frame);
  };

  return (next) => (action) => {
    if (action.type === 'room/subscribeToRoom') {
      const access = action.payload.public ? 'public' : 'private';
      const roomName = action.payload.roomName || null;
      const tags = action.payload.tags || null;

      fetch(`${config.serverUrl}/room/${action.payload.roomId}/init`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ access, stompUsername, roomName, tags }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error('Failed to connect to room');
        })
        .then((data) => {
          console.debug('Init Room Data: ', data);
          localStorage.setItem('user', JSON.stringify(data.user));
          const room = data.room.room;
          // TODO use some pattern to handle this (contributors? RoomData, Media, Users, Chat, Playlist.)
          store.dispatch(setMessages(room.chat.messages));
          store.dispatch(setCurrentTime(room.videoCurrentTime));
          store.dispatch(setIsPlaying(room.videoIsPlaying));
          store.dispatch(setVideoId(room.videoId));
          store.dispatch(setPlaybackRate(Number.parseFloat(room.playbackRate)));
          store.dispatch(setPlaylist(room.playlist || { videos: [] }));
          store.dispatch(setViewMode(room.viewMode || 'YOUTUBE'));
          store.dispatch(setUsers(data.room.users));
          store.dispatch(setCurrentUser(data.user));
          if (!subscribedToSelf) {
            subscribedToSelf = true;
            client.subscribe(`/topic/room/${room.shortId}/user/${data.user.publicId}`, (message) =>
              subscriptionMessageHandler(store, message.action || null, message));
          }
          store.dispatch(setYouTubeShortsIds(data.room.youtubeShortsIds));
          store.dispatch(setCurrentYouTubeShortsIndex(room.youTubeShortsRoomData.currentIndex));
          store.dispatch(setRoomName(room.name));
          store.dispatch(setTags(room.tags));
          store.dispatch(setIsRoomLoading(false));
          store.dispatch(setHistory(room.history?.sort((a, b) => b.timestamp - a.timestamp) || []));
          store.dispatch(setRoomPermissions(room.permissions.entries));
        });
      return next(action);
    } else if (action.type === 'room/connectToWebSocket') {
      client.activate();
      return next(action);
    } else if (action.type === 'room/disconnectFromWebSocket') {
      client.deactivate();
      return next(action);
    }

    const reducer = action.type.split('/')[0];
    if (!socketInterceptors[reducer]) {
      return next(action);
    }
    socketInterceptors[reducer](store, action, client);
    return next(action);
  };
};

const subscriptionMessageHandler = (store, action, message) => {
  const parsedMessage = JSON.parse(message.body);
  return socketMessageHandlers[parsedMessage.type](store, action, parsedMessage);
};


export default socketMiddleWare;
