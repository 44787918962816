const accessControlListInterceptor = (store, action, client) => {
    const baseDestination = `/app/room/${store.getState().room.roomId}/accessControlList`;
    const type = 'accessControlList';

    switch (action.type) {
        case 'accessControlList/updateRoomPermissions':
            client.publish({
                destination: `${baseDestination}/update-room-permissions`,
                body: JSON.stringify({
                    type,
                    action: 'UPDATE_ROOM_PERMISSIONS',
                    payload: action.payload,
                }),
            });
            break;
        default:
            break;
    }
};

export default accessControlListInterceptor;
