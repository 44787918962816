import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  isWebSocketConnected: false,

  roomId: null,
  isPublic: false,
  roomName: null,
  tags: null,
  isRoomSettingsOpen: false,
  viewMode: 'YOUTUBE',

  // TODO das in eigenes media modul auslagern
  videoId: null,
  videoDetails: null,
  isPlaying: false,
  playbackRate: 1,
  currentTime: 0,
  youtubePlayer: null,
  // relevant when user joins room and video is already playing
  initialTime: {
    time: null,
    timeReceived: null,
    initialized: false
  },
};

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setIsWebSocketConnected: (state, action) => {
      state.isWebSocketConnected = action.payload;
    },
    setVideoId: (state, action) => {
      state.videoId = action.payload;
    },
    setVideoDetails: (state, action) => {
      state.videoDetails = action.payload;
    },
    setIsPlaying: (state, action) => {
      console.debug('setPlaying', action.payload);
      state.isPlaying = action.payload;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setPlaybackRate: (state, action) => {
      state.playbackRate = action.payload;
    },
    setInitialTime: (state, action) => {
      state.initialTime = action.payload;
    },
    setRoomName: (state, action) => {
      state.roomName = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    changeViewMode: (state, action) => {
      return;
    },
    publishMessage: () => {
      return;
    },
    connectToWebSocket: (state, action) => {
      return;
    },
    disconnectFromWebSocket: (state, action) => {
      return;
    },
    subscribeToRoom: (state, action) => {
      state.roomId = action.payload.roomId;
      state.isPublic = action.payload.public || false;
      state.roomName = action.payload.roomName || null;
      state.tags = action.payload.tags || null;
      return;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsRoomSettingsOpen: (state, action) => {
      state.isRoomSettingsOpen = action.payload;
    },
    play: (state, action) => {
      return;
    },
    pause: (state, action) => {
      return;
    },
    changeVideo: (state, action) => {
      return;
    },
    changePlaybackRate: (state, action) => {
      return;
    },
    sendCurrentTimeToUser: (state, action) => {
      return;
    },
  },
});

export const {
  setVideoId,
  setIsPlaying,
  setCurrentTime,
  publishMessage,
  connectToWebSocket,
  disconnectFromWebSocket,
  subscribeToRoom,
  setIsWebSocketConnected,
  play,
  pause,
  changeVideo,
  setVideoDetails,
  setRoomName,
  setTags,
  setIsLoading,
  setViewMode,
  changePlaybackRate,
  setPlaybackRate,
  sendCurrentTimeToUser,
  setInitialTime,
  changeViewMode,
  setIsRoomSettingsOpen
} = roomSlice.actions;

export default roomSlice.reducer;

