import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    roomPermissions: []
};

export const accessControlListSlice = createSlice({
    name: 'accessControlList',
    initialState,
    reducers: {
        setRoomPermissions: (state, action) => {
            state.roomPermissions = action.payload;
        },
        updateRoomPermissions: () => {
            return;
        }
    },
});

export const {
    setRoomPermissions,
    updateRoomPermissions,
} = accessControlListSlice.actions;

export default accessControlListSlice.reducer;
