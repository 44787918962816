import { useContext, useEffect } from 'react';
import { useState } from 'react';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import config from '../config';
import styles from './PublicRoomsScreen.module.css';
import { Chip } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { CreatePublicRoomModalContext } from './CreatePublicRoomModalProvider';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import {ReactComponent as HeaderImage} from '../images/Hero_Space_Illustration.svg';

const PublicRoomsScreen = () => {
  const [publicRooms, setPublicRooms] = useState([]);
  const navigate = useNavigate();
  const setCreatePublicRoomModalOpen = useContext(CreatePublicRoomModalContext);

  useEffect(() => {
    fetch(`${config.serverUrl}/room/public`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Response not ok');
        }
        return response.json();
      })
      .then((data) => {
        setPublicRooms(data);
      });
  }, []);


  return (
    <div className={[styles.PublicRoomsScreen, 'public-rooms'].join(' ')}>
      <div className={styles.Header}>
        <div className={styles.Left}>
          <h1>Join a space...</h1>
          <h2>
            ...where you can engage with others, watch YouTube videos together,
            participate in virtual fitness activities like yoga, learn programming
            and study together, or simply socialize. Create connections, share
            interests and make the most of your time together.
          </h2>
        </div>
        <div className={styles.Right}>
          <HeaderImage />
        </div>
      </div>
      {publicRooms.length === 0
        ? <div className={styles.NoPublicRooms}>
          <h3>It looks like there is currently no active public room.</h3>
          <div className={styles.CreatePublicRoom}
            onClick={() => setCreatePublicRoomModalOpen(true)}>
            <AddBoxRoundedIcon className={styles.AddIcon} />
            <div>Create your own public room</div>
          </div>
        </div>
        : <>
          <h3>Watch YouTube together by joining a public room</h3>
          <div className={styles.PublicRooms}>
            {publicRooms.map((entry) => (
              <div key={entry.room.shortId}
                className={styles.PublicRoom}
                onClick={() => navigate(`/room/${entry.room.shortId}`)}>
                <div className={styles.RoomThumbnailWrapper}>
                  <img className={styles.RoomThumbnail}
                    src={`https://i.ytimg.com/vi_webp/${entry.room.videoId}/hqdefault.webp`}
                    alt='current video thumbnail' />
                  <LoginRoundedIcon className={styles.JoinIcon} />
                </div>
                <div className={styles.RoomInfo}>
                  <div className={styles.RoomName}>{entry.room.name}</div>
                  <div className={styles.CurrentUserAmountWrapper}>
                    <GroupRoundedIcon />
                    <div className={styles.Amount}>
                      {entry.users.filter(u => u.online).length}/{entry.users.length}
                    </div>
                  </div>
                </div>
                <div className={styles.TagList}>
                  {entry.room.tags.map((tag) => (
                    <Chip key={tag} >
                      {tag}
                    </Chip>
                  ))}
                </div>
              </div>
            ))}
            <div className={styles.CreatePublicRoom}
              onClick={() => setCreatePublicRoomModalOpen(true)}>
              <AddBoxRoundedIcon className={styles.AddIcon} />
              <div>Create your own public room</div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default PublicRoomsScreen;
