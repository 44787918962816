import ShortUniqueId from 'short-unique-id';

const playlistInterceptor = (store, action, client) => {
  const baseDestination = `/app/room/${store.getState().room.roomId}/playlist`;
  const type = 'playlist';

  switch (action.type) {
    case 'playlist/updatePlaylist':
      client.publish({
        destination: `${baseDestination}/update`,
        body: JSON.stringify({
          type,
          action: 'UPDATE',
          payload: action.payload,
        }),
      });
      break;
    case 'playlist/addToPlaylist':
      client.publish({
        destination: `${baseDestination}/update`,
        body: JSON.stringify({
          type,
          action: 'UPDATE',
          payload: {
            videos: [...store.getState().playlist.playlist.videos, {
              ...action.payload,
              shortId: new ShortUniqueId().randomUUID(10)
            }],
          },
        }),
      });
      break;
    case 'playlist/removeFromPlaylist':
      client.publish({
        destination: `${baseDestination}/update`,
        body: JSON.stringify({
          type,
          action: 'UPDATE',
          payload: {
            videos: [...store.getState().playlist.playlist.videos
              .filter(video => video.shortId !== action.payload)],
          },
        }),
      });
      break;
    default:
      break;
  }
};

export default playlistInterceptor;
