import './App.css';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesContainer from './routes/RoutesContainer';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store/store';
import Header from './layout/Header';
import AskForCookies from './cookie/AskForCookies';
import { UserPermissionsProvider } from './access_control_list/UserPermissionsProvider';

const organizationStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  'name': 'Flock Video - Watch YouTube together',
  'url': 'https://flock.video',
  'logo': 'https://flock.video/logo.svg'
};

const softwareApplicationStructuredData = {
  '@context': 'https://schema.org',
  '@type': 'SoftwareApplication',
  'name': 'Flock Video - Watch YouTube together',
  'operatingSystem': 'Web',
  'applicationCategory': 'EntertainmentApplication'
};

function App() {
  const colorScheme = extendTheme({
    colorSchemes: {
      dark: {
        palette: {
          primary: {
            '500': '#292ec1c2',
            'main': '#840405',
            'solidHoverBg': 'black',
            'solidBg': '#840405',
            'solidDisabledBg': 'var(--grey-input)',
            'solidDisabledColor': 'var(--text-grey-2)',
          },
          neutral: {
            solidBg: 'black',
          },
          secondary: {
            main: 'black',
          },
          background: {
            default: '#181A20',
            paper: '#2b3139',
            surface: 'var(--grey-input-dark)'
          },
          grey: {
            header: '#181A20',
          },
        }
      }
    },
    components: {
      JoyTextField: {
        styleOverrides: {
          root: {
            '& .JoyInput-root': {
              background: 'var(--grey-input)',
              borderRadius: 'var(--input-border-radius)',
            },
            '& .JoyInput-endDecorator': {
              color: 'var(--text-grey-2)',
            },
          },
        },
      },
    },
  });

  console.debug(colorScheme);

  return (
    <ReduxProvider store={store}>
      <script type="application/ld+json">{JSON.stringify(organizationStructuredData)}</script>
      <script type="application/ld+json">{JSON.stringify(softwareApplicationStructuredData)}</script>
      <CssVarsProvider defaultMode='dark' theme={colorScheme}>
        <CssBaseline />
        <UserPermissionsProvider >
          <Router className="App">
            <Header />
            <RoutesContainer />
          </Router>
        </UserPermissionsProvider>
        <AskForCookies />
      </CssVarsProvider>
    </ReduxProvider>
  );
}

export default App;
