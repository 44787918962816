
const youtubeShortsInterceptor = (store, action, client) => {
  const baseDestination = `/app/room/${store.getState().room.roomId}/youtubeShorts`;
  const type = 'youtubeShorts';

  switch (action.type) {
    case 'youtubeShorts/changeCurrentIndex':
      client.publish({
        destination: `${baseDestination}/changeCurrentIndex`,
        body: JSON.stringify({
          type,
          action: 'CHANGE_CURRENT_INDEX',
          payload: action.payload,
        }),
      });
      break;
    default:
      break;
  }
};

export default youtubeShortsInterceptor;
