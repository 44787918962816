/**
 * Formats:
 * https://www.youtube.com/watch?v=9bZkp7q19f0
 * https://youtu.be/9bZkp7q19f0
 * https://www.youtube.com/shorts/tnZzRRIWWDA
 */
class YouTubeUrlParser {
    NORMAL_URL = 'https://youtube.com/watch';
    NORMAL_URL_WITH_SUBDOMAIN = 'https://www.youtube.com/watch';
    SHORTS_URL = 'https://youtube.com/shorts/';
    SHORTS_URL_WITH_SUBDOMAIN = 'https://www.youtube.com/shorts/';
    SHARE_URL = 'https://youtu.be/';

    parse(url) {
        if (url.startsWith(this.SHARE_URL)) {
            return url.substring(this.SHARE_URL.length);
        } else if (url.startsWith(this.NORMAL_URL_WITH_SUBDOMAIN)
            || url.startsWith(this.NORMAL_URL)) {
            const params = new URLSearchParams(new URL(url).search);
            return params.get('v');
        } else if (url.startsWith(this.SHORTS_URL_WITH_SUBDOMAIN)) {
            const pathAndQuery = url.substring(this.SHORTS_URL_WITH_SUBDOMAIN.length);
            return pathAndQuery.split('?')[0];
        } else if (url.startsWith(this.SHORTS_URL)) {
            const pathAndQuery = url.substring(this.SHORTS_URL.length);
            return pathAndQuery.split('?')[0];
        } else {
            throw new Error('Invalid YouTube URL');
        }
    }
}

const youtubeUrlParser = new YouTubeUrlParser();

export default youtubeUrlParser;
