const webRTCInterceptor = (store, action, client) => {
  const baseDestination = `/app/room/${store.getState().room.roomId}/user`;
  const type = 'webRTC';

  switch (action.type) {
    case 'webRTC/sendSignal':
      client.publish({
        destination: `${baseDestination}/${action.payload.destination}/signaling`,
        body: JSON.stringify({
          type,
          action: 'SEND_SIGNAL',
          payload: action.payload,
        }),
      });
      break;
    default:
      break;
  }
};

export default webRTCInterceptor;
