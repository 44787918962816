import { Button, Tooltip } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { changeVideo, changeViewMode } from '../rooms/roomSlice';
import { addToPlaylist } from '../playlist/playlistSlice';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';

import styles from './History.module.css';
import config from '../config';
import { useContext } from 'react';
import { PERMISSIONS, UserPermissionsContext } from '../access_control_list/UserPermissionsProvider';

const History = () => {
    const history = useSelector(state => state.history.entries);
    const dispatch = useDispatch();
    const hasPermissionFor = useContext(UserPermissionsContext);
    const viewMode = useSelector((state) => state.room.viewMode);

    const handleAddToPlaylist = (videoId) =>
        fetch(`${config.youtubeSearchApiEndpoint}/video-details?videoId=${videoId}`)
            .then((response) => response.json())
            .then((data) => {
                dispatch(addToPlaylist({
                    id: videoId,
                    title: data.title,
                    channel: data.channel,
                }));
            });

    return (
        <div className={styles.History}>
            <h2>History</h2>
            {history.length < 2 && <div className={styles.Empty}>Nothing played yet.</div>}
            {history.slice(1).map((entry, index) => (
                <div key={entry.timestamp} className={styles.HistoryEntry}>
                    <div className={styles.Thumbnail}>
                        <img src={`https://i.ytimg.com/vi_webp/${entry.videoId}/hqdefault.webp`} alt={entry.title} />
                    </div>
                    <div className={styles.Description}>
                        <div className={styles.Title}>
                            <Tooltip title={entry.title || ''} placement="right">
                                <span>{entry.title}</span>
                            </Tooltip>
                        </div>
                        <div className={styles.Channel}>{entry.channel}</div>
                    </div>
                    {hasPermissionFor(PERMISSIONS.mediaControl) &&
                        <div className={styles.Actions}>
                            <Tooltip title="Add to playlist" placement="bottom">
                                <Button
                                    sx={{
                                        padding: '4px',
                                        backgroundColor: 'var(--grey-input)',
                                    }}
                                    onClick={() => handleAddToPlaylist(entry.videoId)}>
                                    <PlaylistAddRoundedIcon className={styles.ActionIcon} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Play again" placement="bottom">
                                <Button
                                    sx={{
                                        padding: '4px',
                                        backgroundColor: 'var(--grey-input)',
                                    }}
                                    onClick={() => {
                                        let setOffMs = 0;
                                        if (viewMode !== 'YOUTUBE') {
                                            dispatch(changeViewMode('YOUTUBE'));
                                            setOffMs = 10;
                                        }
                                        setTimeout(() => {
                                            dispatch(changeVideo(entry.videoId));
                                        }, setOffMs);
                                    }}>
                                    <PlayArrowRoundedIcon className={styles.ActionIcon} />
                                </Button>
                            </Tooltip>
                        </div>}
                </div>
            ))}
        </div>
    );
};

export default History;
